import axios from 'axios';
import { BLACKWING_API_URI, API_URI } from 'config';
import { parseParameters } from 'config/helpers';

export const getGeofence = (opt: any, onSuccess: (r: any) => void, onError: (r: any) => void) => {
  const t = opt.token;
  axios.get(`${BLACKWING_API_URI}/geofence/`, {
    params: parseParameters(opt),
    headers: { authorizationToken: `Bearer ${t}` }
  })
  .then(response => {
    onSuccess(response);
  })
  .catch(error => {
    onError(error);
  })
}


export const onboardUser = (opt: any, onSuccess: (r: any) => void, onError: (r: any) => void) => {
  const t = opt.token;
  axios.post(`${API_URI}/auth/onboarding`, {}, {
    headers: { authorizationToken: `Bearer ${t}` }
  })
  .then(response => {
    onSuccess(response);
  })
  .catch(error => {
    onError(error);
  })
}
import React from 'react';

import {
  ImagesIcon,
  CrossIcon,
  ArrowIcon,
  CalendarIcon,
  LoadingIcon,
  PinIconFilled
} from 'components/icons';
import { StatusLabel } from 'components/statuslabel';
import { ConfirmationModal } from 'components/modal/confirmationmodal';
import { TabsContainer } from 'components/tabscontainer';
import { SimpleCaptureCard } from 'components/capturecard';
import { Button } from 'components/button';
import {
  getPolygonArea,
  getPreviewImageForAOI,
  isAreaProductById,
  recurrenceValues
} from 'config/helpers';

import {
  TaskDetailStyled,
  ImageWrapper,
  ImageContainer,
  TaskInfo,
  TaskTitle,
  TaskCoordinates,
  TaskContent,
  TaskDesc,
  TaskRow,
  TaskRowTitle,
  TaskType,
  TaskDate,
  UpcomingCapturesList,
  UCListItem,
  UCItemDate,
  UCItemStatus,
  LoadingWrapper,
  NoDataLabel,
  ImageOverlay,
  CancelButtonContainer,
  markerIconStyle
} from './taskdetail.styles';

import { classes } from 'config/common';
import { coordinatesFormater, dateFormater } from 'config/helpers';
import { useModal } from 'config/hooks';
import { Task, Product } from 'typings';
import { toJS } from 'mobx';

export interface Props {
  // Area center
  areaCenter?: { lat: number; lng: number };
  /** CSS class name **/
  className?: string;
  // Product list
  products: Product[];
  rounded?: boolean;
  /** Task info */
  task?: Task;
  /** Confirmation modal cancel action */
  onCancelTask?: (t: Task, success?: () => void) => void;
  /** onClose function */
  onClose: () => void;
}

export const TaskDetail = ({
  className,
  products,
  rounded,
  task,
  onCancelTask,
  onClose
}: Props) => {
  const { isShown, toggle } = useModal();
  if (!task) return null;

  const { task_name, target, start, end, created_time, status, captures } = task;

  const publishedCaptures: any = captures?.filter((item: any) => item.status === 'published');

  const isAreaProduct = isAreaProductById(task.product, products);

  const cancelTaskAction = (t: Task, callback?: () => void) => {
    if (onCancelTask)
      onCancelTask(t, () => {
        toggle();
        if (callback) callback();
      });
  };

  const productMatcher = (pk: number) =>
    products ? products.find(item => item.pk === pk)?.name : pk;

  const formatStatus = (status: string) => {
    return status === 'saturated' ? 'clouds' : status;
  };

  const getDateMarkup = (date: string) => {
    const dateArray = dateFormater(date).split(' ');
    return (
      <div className="date">
        <span>{dateArray[0]}</span>
        <span>{`${dateArray[1]} ${dateArray[2]}`}</span>
      </div>
    );
  };

  const getCaptureStatusList = () => {
    // Show loading icon while fetching captures
    if (!captures) {
      return (
        <LoadingWrapper>
          <LoadingIcon className="loading--icon" />
        </LoadingWrapper>
      );
    }

    // Show captures list if there are captures to show
    if (captures && captures.length > 0) {
      return (
        <UpcomingCapturesList>
          {captures.map((capture, index) => (
            <UCListItem key={index}>
              <UCListItem>
                <ImagesIcon />
                <UCItemDate>{dateFormater(capture.start)}</UCItemDate>
              </UCListItem>
              {capture.status && <UCItemStatus>{formatStatus(capture.status)}</UCItemStatus>}
            </UCListItem>
          ))}
        </UpcomingCapturesList>
      );
    }
    // Show text based on status
    if (captures && captures.length === 0) {
      let text;

      switch (status) {
        case 'received':
        case 'planning':
          text = 'Awaiting capture opportunities';
          break;
        case 'canceled':
          text = 'No captures produced before task was canceled';
          break;
        case 'rejected':
        case 'failed':
          text = 'No captures were produced for this task';
          break;
      }
      return <NoDataLabel>{text}</NoDataLabel>;
    }
  };

  const infoContent = (
    <>
      <TaskRow>
        <TaskRowTitle>Task duration</TaskRowTitle>
        <TaskDate>
          <CalendarIcon className="calendar--icon" />
          {start ? getDateMarkup(start) : '-'} <ArrowIcon className="arrow--icon" />{' '}
          {end ? getDateMarkup(end) : '-'}
        </TaskDate>
      </TaskRow>
      <TaskRow>
        <TaskRowTitle>Creation date</TaskRowTitle>
        <TaskDate>
          <CalendarIcon className="calendar--icon" />
          {dateFormater(created_time)}
        </TaskDate>
      </TaskRow>
      <TaskRow>
        <TaskRowTitle>Recurrence</TaskRowTitle>
        <TaskDate>
          {task.expected_age === undefined ? (
            <LoadingIcon className="loading--icon" />
          ) : (
            recurrenceValues.find(item => item.value === task.expected_age)?.text
          )}
        </TaskDate>
      </TaskRow>
      <TaskRow>
        <TaskRowTitle>Capture status</TaskRowTitle>
        {getCaptureStatusList()}
      </TaskRow>
    </>
  );

  const capturesContent = publishedCaptures?.map((item: any, k: number) => (
    <SimpleCaptureCard item={item} name={task_name} key={k} />
  ));

  let tabs: any = [
    {
      label: 'Info',
      content: infoContent
    }
  ];

  if (capturesContent && capturesContent.length > 0) {
    tabs.push({
      label: 'Captures',
      content: capturesContent
    });
  }

  const showCancelTask =
    onCancelTask &&
    status &&
    ['received', 'pending', 'in_progress', 'planning'].indexOf(status) !== -1;

  return (
    <TaskDetailStyled className={classes(className, { rounded })}>
      <ImageWrapper>
        {target ? (
          <>
            <ImageContainer>
              {!!task.areaCenter ? (
                <>
                  <img
                    src={`https://tiles.stadiamaps.com/static/alidade_smooth.png?${getPreviewImageForAOI(
                      toJS(task.target?.coordinates[0]),
                      toJS(task.target?.type),
                      toJS(task.areaCenter)
                    )}`}
                    alt="img-AOI"
                  />
                  {
                    // @ts-ignore
                    toJS(task.target?.coordinates[0]).length > 200 ? (
                      <PinIconFilled className="pin--icon" style={markerIconStyle} />
                    ) : null
                  }
                </>
              ) : (
                <div style={{ position: 'relative' }}>
                  <img
                    src={`https://tiles.stadiamaps.com/static/alidade_smooth.png?center=${target.coordinates[1]},${target.coordinates[0]}&zoom=8&size=350x144`}
                    style={{ marginBottom: '-3px' }}
                    alt="img-POI"
                  />
                  <PinIconFilled className="pin--icon" style={markerIconStyle} />
                </div>
              )}
              <CrossIcon className="close--icon" onClick={onClose} />
              <ImageOverlay />
            </ImageContainer>
          </>
        ) : (
          <LoadingWrapper>
            <LoadingIcon className="loading--icon" />
          </LoadingWrapper>
        )}
      </ImageWrapper>

      <TaskInfo>
        <TaskTitle>{task_name}</TaskTitle>
        {!isAreaProduct &&
        target?.coordinates[0] &&
        target.coordinates[1] &&
        target.type === 'Point' ? (
          <TaskCoordinates>
            {coordinatesFormater([target.coordinates[1], target.coordinates[0]])}
          </TaskCoordinates>
        ) : target?.type === 'Polygon' ? (
          <TaskCoordinates>
            <span>
              {getPolygonArea(target.coordinates).toFixed(2)} km<sup>2</sup>
            </span>
          </TaskCoordinates>
        ) : null}
      </TaskInfo>
      <TaskContent>
        <TaskRow className="space-between wrap">
          {task.product && <TaskType>{productMatcher(task.product)}</TaskType>}
          {status && <StatusLabel status={status} />}
          {!task.status_details || task.status_details === undefined ? (
            ''
          ) : (
            <TaskDesc>{`${task.status_details?.[0]}`}</TaskDesc>
          )}
        </TaskRow>
        <TabsContainer tabs={tabs}  selectedTab={undefined} setSelectedTab={undefined}/>
      </TaskContent>
      {showCancelTask && (
        <CancelButtonContainer>
          <Button onClick={toggle} text="Cancel Task" className="cancel-button" secondary />
        </CancelButtonContainer>
      )}
      {onCancelTask && (
        <ConfirmationModal
          title="Cancel the task"
          text={`Are you sure you want to cancel this task? This action can't be undone.`}
          cancelText="No, take me back"
          acceptText="Yes, cancel the task"
          onCancel={toggle}
          onConfirm={callback => cancelTaskAction(task, callback)}
          isShown={isShown}
          onClose={toggle}
        />
      )}
    </TaskDetailStyled>
  );
};

TaskDetail.displayName = 'TaskDetail';

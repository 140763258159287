
import { FeatureCollection } from '@turf/helpers';
import { MapLayerMouseEvent } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { draw_styles } from './helpers';
//let drawRef = useRef<MapboxDraw | null>(null);

let ref: MapboxDraw | null = null;
let drawChangedCallback: ((ev: MapLayerMouseEvent) => void) | null = null;

const createCallback = (newPolyCallback: (poly: FeatureCollection | undefined) => void) => (ev: any) => {
    if (ref){
        const polys = ref.getAll();
        //@ts-ignore
        if (newPolyCallback) newPolyCallback(polys);
    }
}

export const drawPolygonSetup = (map: mapboxgl.Map, newPolyCallback: (poly: FeatureCollection | undefined) => void) => {
    
    drawChangedCallback = createCallback(newPolyCallback);
    
    if (!ref){
        ref = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true
            },
            styles: draw_styles
        });
    
        // --- Add and set the styles of the control
        const originalOnAdd = ref.onAdd.bind(ref);
        ref.onAdd = map => {
            const controlContainer = originalOnAdd(map);
            controlContainer.classList.add('maplibregl-ctrl', 'maplibregl-ctrl-group');
            return controlContainer;
        };
    
        map.addControl(ref, 'top-left');
    
        map.on('draw.create', drawChangedCallback);
        map.on('draw.update', drawChangedCallback);
        map.on('draw.delete', drawChangedCallback);
        
        ref.changeMode("draw_polygon"); // automatically start drawing
    }
    
};

export const drawPolygonTearDown = (map: mapboxgl.Map) => {
    if (ref && map.hasControl(ref)) {
        map.removeControl(ref);
    }
    ref = null;
    drawChangedCallback = null;
}

/*
const updateDrawnPolygon = () => {
    archiveStore.setAoiPolygon(drawRef.current?.getAll());

    const polygonArea = calculateTotalArea(archiveStore.aoiPolygon)
    archiveStore.setAOIArea(Number(polygonArea))
  }

  const handleReDrawPoylgon = () => {
    if (drawRef.current) {
      console.log(drawRef.current)
      drawRef.current.changeMode("draw_polygon");
    }
  }

  const handleDrawPolygon = () => {

    const map = archiveStore.mapRefStore?.getMap()

    archiveStore.setAoiSearchMode("DrawPolygon");

    if (map && drawRef.current === null) {
      drawRef.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true
        },
        styles: draw_styles
      });

      // --- Add and set the styles of the control
      const originalOnAdd = drawRef.current.onAdd.bind(drawRef.current);
      drawRef.current.onAdd = map => {
        const controlContainer = originalOnAdd(map);
        controlContainer.classList.add('maplibregl-ctrl', 'maplibregl-ctrl-group');
        return controlContainer;
      };

      if (!map.hasControl(drawRef.current)) {

        map.on('draw.create', updateDrawnPolygon);
        map.on('draw.update', updateDrawnPolygon);
        map.on('draw.delete', updateDrawnPolygon);

        map.addControl(drawRef.current, 'top-right');

      }

      archiveStore.setAOIArea(undefined)

      drawRef.current.changeMode("draw_polygon");
    }
  }

  const handleStopDrawPolygon = () => {
    const map = archiveStore.mapRefStore?.getMap() as unknown as MaplibreMap;


    if (!!map && !!drawRef && !!drawRef.current) {
      if (map.hasControl(drawRef.current as unknown as IControl)) {
        map.removeControl(drawRef.current as unknown as IControl);
      }

      drawRef.current = null;
      archiveStore.setAoiPolygon(undefined);
      //drawRef.current.changeMode("simple_select")      
    }
  }
*/
import { useControl } from 'react-map-gl/maplibre';
import { useEffect, useRef, useState } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Title } from './rasterControl.styled';

// Define your custom control
export function RasterControl(props: { position: any, options:  { key: string; display_name: string }[], onClick?: (name: string) => void }) {

    const controlRef = useRef<HTMLDivElement | null>(null);
    const rootRef = useRef<Root | null>(null); // To store the root for React 18 rendering
    const [selectedAsset, setSelectedAsset] = useState<string | null>(null);

    // This will create and return the control using the useControl hook from react-map-gl
    useControl(
        () => {
            const control = {
                onAdd: () => {
                    const container = document.createElement('div');
                    container.className = 'maplibregl-ctrl maplibregl-ctrl-group';
                    controlRef.current = container;

                    // Create root for React 18 rendering
                    rootRef.current = createRoot(container);

                    // Initial render of the content inside the container
                    renderContent(props.options);                    
                    return container;
                },                
                onRemove: () => {
                    if (rootRef.current) {
                        rootRef.current.unmount(); // Unmount React component when control is removed
                    }
                    controlRef.current?.parentNode?.removeChild(controlRef.current);
                    controlRef.current = null;
                    rootRef.current = null;
                }
            };
            return control;
        },
        { position: props.position }
    );

    // Update the content of the control when the options prop changes
    useEffect(() => {
        if (controlRef.current && rootRef.current) {            
            renderContent(props.options); // Re-render when options change            
        }
    }, [props.options, selectedAsset]);

    // Update the content of the control when the options prop changes
    useEffect(() => {                
        const first = props.options[0]        
        setSelectedAsset(first.key); // Update the selected state        
        if (props.onClick) props.onClick(first.key);
    }, [props.options]);    

    // Handle asset button click
    const handleAssetClick = (e: React.ChangeEvent<HTMLInputElement> ) => {
        const targetValue = (e.target as HTMLInputElement).value;

        console.log(targetValue)
        setSelectedAsset(targetValue); // Update the selected state        
        if (props.onClick) props.onClick(targetValue);
    };

    // Function to render content inside the control's container using createRoot
    const renderContent = (options:  { key: string; display_name: string }[]) => {
        if (rootRef.current) {
            rootRef.current.render(
                //@ts-ignore
                <div style={{display:"flex", flexDirection:"column", width:"90px"}}>
                    <Title> Raster display </Title>
                    {options && options.map((option) => (
                        <label key={option.key}> {/* Added key here */}
                            <input
                                key={option.key}
                                type="radio"
                                value={option.key}                                
                                checked={selectedAsset === option.key}
                                onChange={handleAssetClick} // Set selected asset on click                            
                            />     
                            {option.display_name}
                            <br/>
                            
                        </label>
                    ))}                    
                </div>
            );
        }
    };

    return null;
}
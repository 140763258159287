import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { CalendarIcon } from 'components/icons';
import { Container, DateInput, InputContainer, Label } from './inputdaterange.styles';
import { classes } from 'config/common';
import { addDays } from 'date-fns';

interface Props {
  className?: string;
  name: string;
  startLabel?: string;
  startValue?: string | Date;
  startMin?: Date;
  maxStartDate?: Date;
  daysAfterStart?: number;
  maxEndDate?: Date;
  onChangeStart?: (date: Date) => void;
  endLabel?: string;
  endValue?: string | Date;
  onChangeEnd?: (date: Date) => void;
  clear?: boolean;
  monthsShown?: number;
  startPlacement?: string;
  endPlacement?: string;
  disabled?: boolean;
}

export const InputDateRange = ({
  className,
  name,
  startLabel,
  startValue,
  startMin,
  maxStartDate,
  daysAfterStart = 0,
  maxEndDate,
  onChangeStart,
  endLabel,
  endValue,
  onChangeEnd,
  clear,
  monthsShown = 2,
  startPlacement = 'bottom-end',
  endPlacement = 'bottom-end',
  disabled = false
}: Props) => {
  let endRef = React.useRef<any>();

  const dateValidator = (date: string | Date | undefined) => {
    return date ? typeof date === "string" ? new Date(date.substr(0, 10)) : date : null;
  }

  const [startDate, setStartDate] = useState<Date | null>(dateValidator(startValue));
  const [endDate, setEndDate] = useState<Date | null>(dateValidator(endValue));

  React.useEffect(() => {
    setStartDate(dateValidator(startValue));
  }, [startValue]);

  React.useEffect(() => {
    setEndDate(dateValidator(endValue));
  }, [endValue]);


  const handleChangeStart = React.useCallback(
    (date: Date | null) => {      
      if (onChangeStart && !!date) {
        onChangeStart(date);
        setEndDate(null);
      }
      setStartDate(date);
    },
    [onChangeStart]
  );


  const handleChangeEnd = React.useCallback(
    (date: Date | null) => {
      if (onChangeEnd && !!date) {
        onChangeEnd(date);
      }
      setEndDate(date);
    },
    [onChangeEnd]
  );

  const resetDateRange = clear && startDate != null && endDate != null;

  React.useEffect(() => {
    if (resetDateRange) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [resetDateRange]);

  const addMonthClass = () => {
    const monthNodes = document.querySelectorAll('.react-datepicker__month-container');
    monthNodes.forEach(monthNode => {
      const daysOfCurrentMonth = monthNode.querySelectorAll(
        '.react-datepicker__day:not(.react-datepicker__day--outside-month)'
      );
      const lastDayIndex = daysOfCurrentMonth.length - 1;
      daysOfCurrentMonth[lastDayIndex].setAttribute('data-last-day', '');
    });
  };

  return (
    <Container className={classes(className)}>
      <InputContainer className={classes('start-date', { active: !!startDate || !!startValue }, { disabled: disabled })}>
      
        <DateInput
          name={name}
          selected={startDate}
          onChange={(date: Date | null) => handleChangeStart(date)} 
          dateFormat="yyyy/MM/dd"
          calendarClassName={classes('calendar-style')}
          className="input-style"
          formatWeekDay={(nameOfDay: string) => nameOfDay.toString().substring(0, 1)}
          selectsStart
          popperPlacement={startPlacement}
          startDate={startDate}
          endDate={endDate}
          isClearable          
          onSelect={() => {
            if (endRef.current) endRef.current.input.focus();
          }}          
          /*
          onChange={(date: Date | null) => setStartDate(date)}                                                                                
          value={startValue ? format(new Date(startValue), 'yyyy/MM/dd') : ""}                    
          */
          monthsShown={monthsShown}
          maxDate={maxStartDate}
          minDate={startMin}
          onCalendarOpen={addMonthClass}
          onMonthChange={addMonthClass}          
          autoComplete="off"
        />
        <Label>{startLabel}</Label>
        <CalendarIcon className="calendar-icon" />
      </InputContainer>

      <InputContainer className={classes('end-date', { active: !!endDate || !!endValue }, { disabled: disabled })}>
        <DateInput
          name={name}
          selected={endDate}          
          onChange={(date: Date | null) => handleChangeEnd(date)}
          dateFormat="yyyy/MM/dd"
          calendarClassName={classes('calendar-style')}
          className="input-style"
          popperPlacement={endPlacement}
          formatWeekDay={(nameOfDay: string) => nameOfDay.toString().substring(0, 1)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate ? addDays(startDate, daysAfterStart) : startDate}
          maxDate={maxEndDate}
          monthsShown={monthsShown}
          ref={endRef}
          //value={endValue ? format(new Date(endValue), 'yyyy/MM/dd') : undefined}
          onCalendarOpen={addMonthClass}
          onMonthChange={addMonthClass}
          openToDate={endDate ? endDate : startDate || undefined}
          isClearable
          autoComplete="off"          
        />
        <Label>{endLabel}</Label>
        <CalendarIcon className="calendar-icon" />
      </InputContainer>
    </Container>
  );
};

InputDateRange.displayName = 'InputDateRange';

import React, { useEffect } from 'react';
import { LoadingIcon } from 'components/icons';
import { BaseModal } from '../basemodal';
import { Button } from 'components/button'

import {
  Title, Text, ActionButtons, ModalContainer
} from './confirmationmodal.styles';
import DOMPurify from 'dompurify';

export interface ConfirmationModalProps {
  title: string;
  text?: string;
  cancelText?: string;
  acceptText: string;
  onCancel?: () => void;
  onConfirm: (callback?: () => void) => void;
  isShown: boolean;
  onClose: () => void;
  confirmButtonDisabled?: boolean;
  children?: React.ReactNode
}

export const ConfirmationModal = ({ title, text, cancelText, acceptText, onCancel, onConfirm, isShown, onClose, children, confirmButtonDisabled=false }: ConfirmationModalProps) => {

  const [status, setStatus] = React.useState<string>('');

  const onClickAccept = () => {
    setStatus("loading");
    onConfirm(() => setStatus(""))
  }

  const customHide = () => {
    document.body.style.overflow = "auto";
    if (onCancel) onCancel();
  }

  const onKeyDown = (event: KeyboardEvent) => {
    isShown ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset');
    if (event.keyCode === 27 && isShown) {
      customHide();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  });

  return (
    <BaseModal isShown={isShown} hide={onClose}>
      <ModalContainer>
        <Title>{title}</Title>
        {text?
          <Text dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}></Text>:null
        }
        {children}
        <ActionButtons>
          {cancelText && onCancel && <Button text={cancelText} onClick={onCancel} secondary />}
          <Button
            icon={status === 'loading' ? <LoadingIcon className="loading--icon" /> : undefined}
            text={acceptText}
            onClick={onClickAccept}
            disabled={(status === "loading") || confirmButtonDisabled}
          />
        </ActionButtons>
      </ModalContainer>
    </BaseModal>
  )
};

ConfirmationModal.displayName = "ConfirmationModal";

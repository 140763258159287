import React, { createContext, useContext, useState } from 'react';
import { SearchTab } from 'views/archive/tabs/searchTab';
import { ResultsTab } from 'views/archive/tabs/resultsTab';
import { InfoTab } from 'views/archive/tabs/infoTab';
import { TabObject } from 'components/tabscontainer/tabscontainer';

interface TabContextProps {
  tabs: TabObject[];
  updateTabStatus: (targetLabel: string, enabled: boolean) => void;
}

const TabContext = createContext<TabContextProps | undefined>(undefined);

export const TabProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tabs, setTabs] = useState<TabObject[]>([
    {
      label: 'Search',
      content: <SearchTab />,
      enabled: true
    },
    {
      label: 'Results',
      content: <ResultsTab />,
      enabled: false
    },
    {
      label: 'Details',
      content: <InfoTab />,
      enabled: false
    }    
  ]);

  const updateTabStatus = (targetLabel: string, enabled: boolean) => {
    setTabs(prevTabs =>
      prevTabs.map(tab =>
        tab.label === targetLabel
          ? { ...tab, enabled }
          : tab
      )
    );
  };

  return (
    <TabContext.Provider value={{ tabs, updateTabStatus }}>
      {children}
    </TabContext.Provider>
  );
};

// Custom hook to use TabContext
export const useTabContext = () => {
  const context = useContext(TabContext);
  if (!context) {
    throw new Error('useTabContext must be used within a TabProvider');
  }
  return context;
};
import React, { Fragment } from "react"
import { observer } from "mobx-react-lite"
import { useStores } from "config/hooks"

interface Props {
  requireTaskingPermission?: boolean,
  requireArchivePermission?: boolean,
  requireGaiaPermission?: boolean,
  children?: React.ReactNode
}

export const ContractConstraint = observer(({
    requireTaskingPermission = false,
    requireArchivePermission = false,
    requireGaiaPermission = false,
    children,
}: Props) => {
  const {
    rootStore: { userStore }
  } = useStores()

  if (requireTaskingPermission && !userStore.hasTaskingPermission()) return null
  if (requireArchivePermission && !userStore.hasArchivePermission()) return null
  if (requireGaiaPermission && !userStore.hasGaiaPermission()) return null

  return (
    <Fragment>
      {children}
    </Fragment>
  )

});
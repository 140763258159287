export interface ConfigItem {
    asset_name: string;
    options?: string;
    display_name?: string;
}

type RasterConfig = {
    keys: string[];
    config: Record<string, ConfigItem>;
}[];

const rasterConfig: RasterConfig = [
    {
        keys: ["L1D", "l1d", "l1d-sr", "L1D_SR"],
        config: {
            visual: {
                display_name: 'Visual',
                asset_name: "visual",
                options: "bidx=1&bidx=2&bidx=3",
            },            
            ndvi: {
                display_name: 'NDVI',
                asset_name: "analytic",
                options: "expression=(b4-b3)/(b4%2Bb3)&rescale=0,1&colormap_name=greens",
            },            
            false_color: {
                display_name: 'False color',
                asset_name: "analytic",
                options: "bidx=4&bidx=2&bidx=1&rescale=300,5000",
            },
            cloud: {
                display_name: 'Cloud Mask',
                asset_name: "cloud",
                options: "bidx=1",
            }
        }
    },
    {
        keys: ["QUICKVIEW_VISUAL","L1B"],
        config: {
            visual: {
                display_name: 'Visual',
                asset_name: "analytic",
                options: "bidx=1&bidx=2&bidx=3",
            },            
            cloud: {
                display_name: 'Cloud Mask',
                asset_name: "cloud",
                options: "bidx=1",
            }
        }
    },
    {
        keys: ["L1C"],
        config: {
            visual: {
                display_name: 'Preview',
                asset_name: "preview",                
            }
        }
    },
    {
        keys: ["L1A"],
        config: {
            analytic: {
                display_name: 'Analytic',
                asset_name: "preview",                
            },            
        }
    }
    ,
    {
        keys: ["L0"],
        config: {
            analytic: {
                display_name: 'Analytic',
                asset_name: "preview",                
            },            
        }
    }
];

// Lookup function to retrieve the configuration by key
export function getRasterConfigForProduct(level: string): Record<string, ConfigItem> | undefined {    
    const entry = rasterConfig.find(item => item.keys.includes(level));
    return entry?.config;
}

export function getRasterConfigKeysForLevel(level: string): string[] | undefined {    
    const entry = rasterConfig.find(item => item.keys.includes(level));
    return entry ? Object.keys(entry.config) : undefined;
}

export function getRasterDisplayNamesForLevel(level: string): { key: string; display_name: string }[] | undefined {
    const entry = rasterConfig.find(item => item.keys.includes(level));
    return entry
        ? Object.entries(entry.config).map(([key, item]) => ({
              key,
              display_name: item.display_name || item.asset_name
          }))
        : undefined;
}
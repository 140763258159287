// import { css } from 'config/common';
import { css, SerializedStyles } from '@emotion/react';
import { createTheme } from '@mui/material/styles';

// Extend the default Theme interface with your custom properties
declare module '@mui/material/styles' {
  interface Theme {

    styles: SerializedStyles;  // Add `styles` under `caption`
    sizes: {
      xxxxlarge: string;
      xxxlarge: string;
      xxlarge: string;
      xlarge: string;
      large: string;
      medium: string;
      small: string;
      xsmall: string;
      xxsmall: string;
    };
  }

  // Allow configuration using `createTheme`
  interface ThemeOptions {
    sizes?: {
      xxxxlarge?: string;
      xxxlarge?: string;
      xxlarge?: string;
      xlarge?: string;
      large?: string;
      medium?: string;
      small?: string;
      xsmall?: string;
      xxsmall?: string;
    }
    heading1?: SerializedStyles;
      heading2?: SerializedStyles;
      heading3?: SerializedStyles;
      heading4?: SerializedStyles;
      subtitle?: SerializedStyles;
      body?: SerializedStyles;
      small?: SerializedStyles;
      caption?: SerializedStyles;
      gradientScrim?: SerializedStyles;
      gradientLight?: SerializedStyles;
      shadowSoft?: SerializedStyles;
      shadowMedium?: SerializedStyles;
      shadowStrong?: SerializedStyles;
  }
}

const colors = {
  grey: '#aaaaaa',
  primary: '#002340',
  primary10: '#001C3D',
  primary20: '#00163A',
  primary40: '#000A35',
  primary60: '#00002F',
  primary80: '#060025',
  secondary: '#59C8E6',
  secondary10: '#54B3DB',
  secondary20: '#4E9FD1',
  secondary40: '#4479BB',
  secondary60: '#3A58A5',
  secondary80: '#313B8F',
  neutral: '#FFFFFF',
  neutral05: '#F2F2F2',
  neutral10: '#E6E6E6',
  neutral20: '#cdcdcd',
  neutral40: '#9a9a9a',
  neutral60: '#686868',
  neutral80: '#282828',
  neutral100: '#000000',
  successLight: '#D8EACE',
  success: '#50AD72',
  successDark: '#148B6C',
  warningLight: '#FDF9D9',
  warning: '#F5E663',
  warningDark: '#E2CA64',
  infoLight: '#D2DAEE',
  info: '#6884CA',
  infoDark: '#3A58A5',
  errorLight: '#FDD8DF',
  error: '#EB0A33',
  errorDark: '#AE0725',
  backgroundPrimary: '#ebf6ff',
  backgroundSecondary: '#27334a',
  backgroundHelper: '#fafafa',
  feedbackSuccess: '#E3F2EB',
  feedbackSuccessDark: '#2D6249',
  feedbackInformation: '#EAE4F1',
  feedbackInformationDark: '#644587',
  feedbackError: '#F9E2DD',
  feedbackErrorDark: '#D04425',
};

const sizes = {
  xxxxlarge: '2rem',
  xxxlarge: '1.8rem',
  xxlarge: '1.5rem',
  xlarge: '1.3rem',
  large: '1.125rem',
  medium: '1rem',
  small: '0.875rem',
  xsmall: '0.75rem',
  xxsmall: '0.5rem'
};

const styles = {
  heading1: css`
font-family: 'Montserrat', sans-serif;
font-size: ${sizes.xxxlarge};
font-weight: 700;
`,
  heading2: css`
font-family: 'Montserrat', sans-serif;
font-size: ${sizes.xxxlarge};
font-weight: 700;
`,
  heading3: css`
font-family: 'Montserrat', sans-serif;
font-size: ${sizes.xxlarge};
font-weight: 700;
`,
  heading4: css`
font-family: 'Montserrat', sans-serif;
font-size: ${sizes.xlarge};
font-weight: 700;
`,
  subtitle: css`
font-family: 'Montserrat', sans-serif;
font-size: ${sizes.large};
font-weight: 700;
line-height: 24px;
`,
  body: css`
font-size: ${sizes.medium};
font-weight: 400;
`,
  small: css`
font-size: ${sizes.small};
font-weight: 500;
line-height: 21px;
`,
  caption: css`
font-size: ${sizes.small};
font-weight: 400;
`,
  gradientScrim: css`
background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
`,
  gradientLight: css`
background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
`,
  shadowSoft: css`
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
`,
  shadowMedium: css`
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
`,
  shadowStrong: css`
box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
`


}

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      light: colors.primary20,  // Optional: lighter shade
      dark: colors.primary60,   // Optional: darker shade
      contrastText: '#FFFFFF',  // Optional: text color for contrast
    },
    secondary: {
      main: colors.secondary,
      light: colors.secondary10,
      dark: colors.secondary60,
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontSize: sizes.xxxlarge,
      fontWeight: 700,
    },
    h2: {
      fontSize: sizes.xxxlarge,
      fontWeight: 700,
    },
    h3: {
      fontSize: sizes.xxlarge,
      fontWeight: 700,
    },
    h4: {
      fontSize: sizes.xlarge,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: sizes.large,
      fontWeight: 700,
    },
    body1: {
      fontSize: sizes.medium,
      fontWeight: 400,
    },
    body2: {
      fontSize: sizes.small,
      fontWeight: 400,
    },
    caption: {
      fontSize: sizes.small,
      fontWeight: 400,
    },
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
  sizes,
  ...styles,
  ...colors,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
  },
});

export default defaultTheme;



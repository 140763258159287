import React, { createContext, useContext, useState, ReactNode } from 'react';
import archiveStore from 'stores/archiveStore';
import loadingNotificationStore from 'stores/loadingNotificationStore';

// Define the context type
interface ItemContextType {
  areAllSelected: boolean;
  areAllHidden: boolean;
  selectedItems: Set<string>;
  hiddenItems: Set<string>;
  selectAll: () => void;
  unselectAll: () => void;
  hideAll: () => void;
  unhideAll: () => void;
  toggleItem: (id: string) => void;
  hideItem: (id: string) => void;
  unhideItem: (id: string) => void;
}

// Create the context
const ItemContext = createContext<ItemContextType | undefined>(undefined);

// Custom hook to use the ItemContext
export const useItemContext = () => {
  const context = useContext(ItemContext);
  if (context === undefined) {
    throw new Error('useItemContext must be used within an ItemProvider');
  }
  return context;
};

// Provider component
export const ItemProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [hiddenItems, setHiddenItems] = useState<Set<string>>(new Set());

  const areAllSelected = (selectedItems.size > 0 && selectedItems.size === archiveStore.activeProductData?.features.length);
  const areAllHidden = (hiddenItems.size > 0 && hiddenItems.size === archiveStore.activeProductData?.features.length);

  const selectAll = () => setSelectedItems(new Set(archiveStore.activeProductData?.features.map(item => item.id)));
  const unselectAll = () => setSelectedItems(new Set());
  const hideAll = () => setHiddenItems(new Set(archiveStore.activeProductData?.features.map(item => item.id)));
  const unhideAll = () => setHiddenItems(new Set());
  
  const toggleItem = (id: string) => {
    setSelectedItems(prev => {
      const newSelectedItems = new Set(prev);
      if (newSelectedItems.has(id)) {
        newSelectedItems.delete(id);
      } else {
        newSelectedItems.add(id);
      }
      return newSelectedItems;
    });
  };

  const hideItem = (id: string) => {
    setHiddenItems(prev => new Set(prev).add(id));
  };

  const unhideItem = (id: string) => {
    setHiddenItems(prev => {
      const newHiddenItems = new Set(prev);
      newHiddenItems.delete(id);
      return newHiddenItems;
    });
  };

  return (
    <ItemContext.Provider 
    value={{ areAllSelected, areAllHidden, selectedItems, hiddenItems, selectAll, unselectAll, hideAll, unhideAll, toggleItem, hideItem, unhideItem }}>
      {children}
    </ItemContext.Provider>
  );
};

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { MapRef } from 'react-map-gl';
import { FiltersQueriables } from 'typings';
import { Map as MaplibreMap } from 'maplibre-gl';
import loadingNotificationStore from 'stores/loadingNotificationStore';


class ArchiveStore {
  activeCaptureData: { [product: string]: StacSearchResponse } = {};
  activeProductData: StacSearchResponse | null = null;
  activeMetadataItem: string | null = null;  
  aoiArea: any = null;  
  aoiPolygon: any = null; 
  drawPolygon: any = null;
  drawRef: MapboxDraw | null = null;
  showMap: boolean = false;
  mapRefStore: MapRef | null = null;
  bboxSearch: null | number[] = null;
  limitSearchAmount: string = '300';
  collectionsData: Collection[] | [] = [];
  collectionsSearch: string[] | [] = [];
  
  selectedTab: string = "Search";
  dropdownCollectionSelected:
    | {
        value: string;
        text: string;
      }
    | undefined = undefined;

  filtersQueriables: FiltersQueriables = {
    'filter-lang': 'cql2-json',
    filter: {
      op: 'and',
      args: []
    }
  };
  dropdownFilterValues: {
    [key: string]: string | number | string[] | undefined;
  } = {
    'eo:cloud_cover': undefined,
    'satl:outcome_id': '',
    platform: '',
    'view:off_nadir': undefined
  };
  searchResultsThumbnail: StacSearchResponse | null = null;
  activeCapture: FeatureSearch | null = null;
  dateStart: Date;
  dateEnd: Date;
  maplibreMap: MaplibreMap | null = null;
  limitsSearchPolygon: any = null;
  detailListref: React.RefObject<any> | null = null;

  constructor() {
    this.dateStart = new Date(); // Temporary default value
    this.dateEnd = new Date(); // Temporary default value
    makeAutoObservable(this, {      
      setAoiPolygon: action,      
      setDrawPolygon: action,
      setAOIArea: action,
      setActiveCaptureData: action,
      setActiveProductData: action,      
      setShowMap: action,
      setMapRefStore: action,
      setBBoxSearch: action,
      setLimitSearchAmount: action,
      setCollectionsData: action,
      setCollectionsSearch: action,          
      setDropdownCollectionSelected: action,
      setFiltersQueriables: action,      
      setDropdownFilterValues: action,
      setSearchResultsThumbnail: action,
      setSelectedTab: action,
      setActiveCapture: action,
      clearActiveCaptureData: action,
      setDetailListref: action,
      setActiveMetadataItem: action,
      
    });
    this.setDatesToTodayAndPastThirtyDays();
  }

  setSelectedTab = (tab: string) => {    
    this.selectedTab = tab
  }
  setDetailListref(ref: React.RefObject<any>) {
    this.detailListref = ref
  }

  setAOIArea(area: number | undefined){
    this.aoiArea = area;    
  }

  setAoiPolygon(geojson:  any ){
    this.aoiPolygon = geojson     
  }

  setActiveMetadataItem(itemid: string | null){
    this.activeMetadataItem = itemid;
  }
  setDatesToTodayAndPastThirtyDays() {
    const today = new Date();
    today.setUTCHours(23, 59, 59, 999); // Set the time to the end of the day UTC

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    thirtyDaysAgo.setUTCHours(23, 59, 59, 999); // Set the time to the beggining of the day UTC

    this.dateStart = thirtyDaysAgo;
    this.dateEnd = today;
  }

  @action
  setLimitsSearchPolygon(value: any) {
    this.limitsSearchPolygon = value;
  }

  @action
  setMaplibreMap(map: MaplibreMap | null) {
    this.maplibreMap = map;
  }
/*
  @action
  changeDrawMode(mode: string) {
    if (this.drawRef) {
      this.drawRef.changeMode(mode);
    }
  }

  @action
  removeDrawControl(map: MaplibreMap) {
    if (this.drawRef) {
      map.removeControl(this.drawRef as unknown as IControl);
      this.drawRef = null;
    }
  }
*/
  @action
  setDateStart(date: Date) {
    this.dateStart = date;
  }

  @action
  setDateEnd(date: Date) {
    this.dateEnd = date;
  }
  
  setActiveCapture(capture: FeatureSearch | null) {
    this.activeCapture = capture;
  }

  setSearchResultsThumbnail(data: StacSearchResponse) {
    this.searchResultsThumbnail = data;
  }

  setFiltersQueriables(filters: FiltersQueriables) {
    this.filtersQueriables = filters;
  }
/*
  setInputOperators(
    key: string,
    operator: {
      value: string;
      text: string;
    }
  ) {
    this.inputOperators[key] = operator;
  }
*/
  setDropdownFilterValues(key: string, value: string | number | string[] | undefined) {
    this.dropdownFilterValues[key] = value;
  }
  

  setDropdownCollectionSelected(
    value:
      | {
          value: string;
          text: string;
        }
      | undefined
  ) {
    this.dropdownCollectionSelected = value;
  }

  clearActiveCaptureData(){
    this.activeCaptureData = {}
  }

  setActiveCaptureData(product: string, data: StacSearchResponse) {
    this.activeCaptureData = {
      ...this.activeCaptureData,
      [product]: data
    };
  }

  setActiveProductData(data: StacSearchResponse | null) {
    this.activeProductData = data;
  }

  setMapRefStore(ref: MapRef) {
    this.mapRefStore = ref;
  }

  setDrawPolygon(value: any) {
    this.drawPolygon = value;
  }

  setDrawRef(draw: MapboxDraw | null) {
    this.drawRef = draw;
  }
  setShowMap(value: boolean) {
    this.showMap = value;
  }

  setBBoxSearch(bbox: number[]) {
    this.bboxSearch = bbox;
  }

  setLimitSearchAmount(limit: string) {
    this.limitSearchAmount = limit;
  }

  setCollectionsData(data: Collection[]) {
    this.collectionsData = data;
  }

  setCollectionsSearch(data: string[]) {
    this.collectionsSearch = data;
  }

  updateArgs(name: string, value: string | string[] | number | undefined | number[], op: string) {
    runInAction(() => {
      const existingIndex = this.filtersQueriables.filter.args.findIndex(
        (arg: any) =>
          arg.args[0] &&
          typeof arg.args[0] === 'object' &&
          'property' in arg.args[0] &&
          arg.args[0].property === name
      );

      let newArgs;       
      let preArgs: { [key: string]: any } = {};

      if ((value instanceof Array) && (op == "between")) {
        // there may be other cases like between, where arguments must be "inlined"
        // "t_inresect" and "in" need one Array argument
        preArgs = [{ property: name }, value[0], value[1]];        
      } else {
        preArgs = [{ property: name }, value];
      }

      if (existingIndex >= 0) {
        newArgs = this.filtersQueriables.filter.args
          .map((arg: any, index: any) => {
            if (index === existingIndex) {                            
                return value !== undefined
                ? { op, args: preArgs }
                : null;              
            }
            return arg;
          })
          .filter((arg: any) => arg !== null);
      } else {
        newArgs =
          value !== undefined && value !== ''
            ? [...this.filtersQueriables.filter.args, { op, args: preArgs }]
            : this.filtersQueriables.filter.args;
      }

      this.filtersQueriables.filter.args = newArgs;
    });
  }

  copyItemIdToClipboard(id: string) {
    loadingNotificationStore.setText("ID Copied to clipboard");
    loadingNotificationStore.setLoading(true);
    setTimeout(() => loadingNotificationStore.setLoading(false), 2000);
    navigator.clipboard.writeText(id);
  }
}

const archiveStore = new ArchiveStore();
export default archiveStore;

import React from 'react';
import { Map as OLMap } from 'components/map';
import { Marker } from 'components/map/marker';
import { Popup } from 'components/map/popup';
import { VectorFeature } from 'components/map/vectorFeature';
import { toLonLat, fromLonLat } from 'ol/proj';
import { Polygon } from 'ol/geom';
import Feature from 'ol/Feature';
import { add, differenceInDays, getHours, getMinutes, format } from 'date-fns'

import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { Layout } from 'components/layout';
import { PreviewTargetModal } from 'components/modal/previewtargetmodal';
import { ProjectModal } from 'components/modal/projectmodal';
import { Dropdown } from 'components/dropdown';
import { Input } from 'components/input';
import { Textarea } from 'components/textarea';
import { FileUpload } from 'components/fileupload';
import { Button } from 'components/button';
import { PlusIcon, LoadingIcon, DeleteIcon, InfoIcon } from 'components/icons';
import { InputDateRange } from 'components/inputdaterange';
import { ConfirmationModal } from 'components/modal/confirmationmodal';
// import { InputRange } from 'components/inputrange';
import { Checkbox } from 'components/checkbox';

import { getGeometry, validateData, reGenerateGeofence } from './helpers';

import { Product, Project, TaskData, DropdownOptions } from 'typings';
import { coordinatesFormater, isAreaProductById, isPointInsidePolygon, repeatingPeriods, getPolygonArea } from 'config/helpers';
import { useMergeState, useStores, useQuery } from 'config/hooks';

import { publishTask as publishTaskService } from 'services';

import {
  Wrapper,
  Sidebar,
  FormTitle,
  FormSubtitle,
  FormText,
  Form,
  FormGroup,
  MapPopup,
  LoadingWrapper,
  MapContainer,
  DropdownWrapper,
  BigAOIDisclaimer
} from './tasking.styles';

export const Tasking = observer(() => {
  // Setup stores
  const {
    rootStore: { taskStore, notificationStore, userStore }
  } = useStores();

  const navigate = useNavigate();

  React.useEffect(() => {
    taskStore.init();
  }, [taskStore]);

  // Form Data State
  const [data, setData, dataRef] = useMergeState<TaskData>({
    task_name: '',
    project_name: '',
    product: '-1',
    product_text: '',
    target: {
      type: undefined,
      coordinates: []
    },
    start: null,
    end: null,
    coordinates: undefined,
    rating: 5,
    max_captures: 1,
    expected_age: ''
  });
  const [isDataMissing, setIsDataMissing] = React.useState(true);
  const [targetArea, setTargetArea] = React.useState<number | undefined>(undefined)

  // New Project State
  const [newProject, setNewProject] = React.useState<Array<DropdownOptions>>([]);

  // Modal States
  const [showModal, setShowModal] = useMergeState({
    newProject: false,
    preview: false,
    info: false
  });

  // Validation States
  const [valid, setValid] = useMergeState({
    geoJSON: false,
    target: false,
    file: false
  });

  // UI States
  const [showFileUpload, setShowFileUpload] = React.useState(true);
  const [showTextarea, setShowTextarea] = React.useState(true);
  const [showDraw, setShowDraw] = useMergeState({ display: false, erase: false });
  const [clearFileUpload, setClearFileUpload] = React.useState(false);
  const [submittingTask, setSubmittingTask] = React.useState(false);
  const [showBigAOIDisclaimer, setShowBigAOIDisclaimer] = React.useState(false)

  const productIsArea =
    data.product !== '-1' && !!isAreaProductById(data.product, taskStore.products);

  // Check query params to select a new project
  const project = useQuery().get('project');
  React.useEffect(() => {
    if (project) setNewProject([{ value: project, text: project }]);
  }, [project]);

  // Check if there's data missing without showing warnings
  React.useEffect(() => {
    isDataValid(false);
    // eslint-disable-next-line
  }, [data]);

  // Validate if Target is inside Geofence
  const isTargetValid = (latLng: number[]) => {
    const geoFenceCoordinates = userStore.detachedRegeneratedGeofence;
    return (
      !geoFenceCoordinates ||
      (geoFenceCoordinates && isPointInsidePolygon(latLng, geoFenceCoordinates))
    );
  };

  // Handle Map Click
  const handleSingleMapClick = (e: any) => {
    if (dataRef.current.product === '-1' && !data.target.type) return
    if (isAreaProductById(dataRef.current.product, taskStore.products)) return
    const lonLat = toLonLat(e.coordinate);
    setPoint(lonLat[1], lonLat[0]);
  };

  // Handle Place Input Change
  const handleBlurPlaceInput = (e: any) => {
    let description = !!e.target.value
      ? 'Please provide a valid target'
      : 'Please provide one target';

    try {
      const [latitude, longitude] = e.currentTarget.value.split(',');
      if (!latitude || !longitude) {
        throw new Error();
      }

      const lat = parseFloat(latitude?.trim());
      const lng = parseFloat(longitude?.trim());
      if (!lat || !lng) {
        throw new Error();
      }

      const isLatitudeCorrect = lat >= -90 && lat <= 90;
      if (!isLatitudeCorrect) {
        description = `Latitude must be between -90 and 90`;
        throw new Error();
      }
      const isLongitudeCorrect = lng >= -180 && lng <= 180;
      if (!isLongitudeCorrect) {
        description = `Longitude must be between -180 and 180`;
        throw new Error();
      }

      setPoint(lat, lng);
    } catch {
      setData({ coordinates: undefined });
      notificationStore.add({ description });
    }
  };

  // Handle Place Search
  const handlePlaceSearch = (latLong: number[]) => {
    const [lat, lng] = latLong;
    setPoint(Number(lat), Number(lng));
  };

  // Set Point
  const setPoint = (lat: number, lng: number) => {
    if (
      isAreaProductById(dataRef.current.product, taskStore.products) ||
      (dataRef.current.product === '-1' && !data.target.type)
    )
      return;
    const valid = isTargetValid([lat, lng]);
    setValid({ target: valid });
    valid
      ? setData({
          coordinates: fromLonLat([lng, lat]),
          target: { type: 'Point', coordinates: [lat, lng] }
        })
      : notificationStore.add({ description: 'Target must be inside the allowed tasking area' });
  };

  // Delete Target
  const deleteTarget = () => {
    setData({
      coordinates: undefined,
      target: {
        type: undefined,
        coordinates: []
      }
    });
    setValid({
      geoJSON: false,
      target: false,
      file: false
    });
    setShowFileUpload(true);
    setClearFileUpload(true);
    setShowTextarea(true);
    setShowDraw({ display: productIsArea, erase: true });
    setTargetArea(undefined)
  };

  // Handle Draw
  const handleDraw = (feature: Feature) => {
    setShowFileUpload(false);
    const polygon: Polygon = feature.getGeometry() as Polygon
    const coordinates = polygon.getCoordinates();
    const convertedCoordinates = coordinates[0].map(coordinate => toLonLat(coordinate));
    setGeoJSON(
      JSON.stringify({
        type: 'Polygon',
        coordinates: [convertedCoordinates]
      })
    );
  };

  // Handle GeoJSON error
  const geoJSONError = (description: string) => {
    notificationStore.add({ description });
    deleteTarget();
  };

  // Validate GeoJSON and set on Map
  const setGeoJSON = (geoJSON: any) => {
    const geoFenceCoordinates = reGenerateGeofence(userStore.detachedGeofence)
    setShowDraw({ display: false, erase: false })
    setShowBigAOIDisclaimer(false)
    if (geoJSON) {
      const { error, description, geometry } = getGeometry(geoJSON, geoFenceCoordinates)
      let errorMessage = description
      try {
        if (error || !geometry) throw new Error()

        const polygon = new Polygon(geometry.coordinates)
        if (!polygon) throw new Error()

        const polygonArea = getPolygonArea(geometry.coordinates)
        setTargetArea(Number(polygonArea.toFixed(2)))
        if (polygonArea > 500) {
          setShowBigAOIDisclaimer(true)
          errorMessage = 'AOI is too big. The limit is 500 km2.'
          throw new Error()
        }
        if (polygonArea < 50) {
          errorMessage = 'AOI is too small. The minimum is 50 km2.'
          throw new Error()
        }

        if (geometry.coordinates[0].length < 5) {
          errorMessage = 'AOI must have at least 4 vertices.'
          throw new Error()
        }

        setData({ target: geometry })
        setValid({ geoJSON: true, file: false })
      } catch {
        geoJSONError(errorMessage)
      }
    } else {
      deleteTarget()
    }
  };

  // Handle Start and End Times
  const minutes = getMinutes(new Date()).toString().length === 1 ? `0${getMinutes(new Date()).toString()}` : getMinutes(new Date()).toString()
  const oneHourFromNow = `${getHours(add(new Date(), { hours: 1 })).toString()}:${minutes}`
  const maxTime = '23:59'

  const [showTimeInputs, setShowTimeInputs] = React.useState(false)
  const [validStartTime, setValidStartTime] = React.useState(true)
  const [startTime, setStartTime] = React.useState('00:00')
  const [minStartTime, setMinStartTime] = React.useState('00:00')
  const [validEndTime, setValidEndTime] = React.useState(true)
  const [endTime, setEndTime] = React.useState('23:59')
  const [minEndTime, setMinEndTime] = React.useState('00:00')

  React.useEffect(() => {
    if (!showTimeInputs) {
      setStartTime(minStartTime)
      setEndTime('23:59')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTimeInputs])

  const dateIsValid = (date: string) => {
    return !Number.isNaN(new Date(date).getTime());
  }

  React.useEffect(() => {
    // If both dates are set and are valid dates
    if (!!data.start && dateIsValid(data.start) && !!data.end && dateIsValid(data.end)) {
      // If start date is today, set the min start time to current time + 1 hour
      if (new Date().toISOString().split('T')[0] === new Date(data.start).toISOString().split('T')[0]) {
        setMinStartTime(oneHourFromNow)
        if (startTime === '00:00') {
          setStartTime(oneHourFromNow)
        } else {
          if (startTime < oneHourFromNow) {
            setStartTime(oneHourFromNow)
          }
        }
      } else {
        setMinStartTime('00:00')
      }

      // If end date is one day after start date, set min end time to 24 hours after start time
      if (differenceInDays(new Date(data.end.split('T')[0]), new Date(data.start.split('T')[0])) === 1) {
        setMinEndTime(startTime)
        if (endTime < startTime) {
          setEndTime(startTime)
        }
      } else {
        setMinEndTime('00:00')
      }
      setData({
        start: `${new Date(data.start).toISOString().substr(0, 10)}T${startTime}:00Z`,
        end: `${new Date(data.end).toISOString().substr(0, 10)}T${endTime}:00Z`
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.start, data.end, startTime, endTime])

  // Validate Data
  const isDataValid = (notify: boolean) => {
    let isValid = true
    const { notification, dataIsMissing } = validateData(data, productIsArea);

    if (notify && notification) notificationStore.add(notification);

    setIsDataMissing(dataIsMissing);

    if (dataIsMissing) isValid = false

    return isValid;
  };

  // Validate and Submit new Task
  const requestTask = async () => {
    if (submittingTask) return;
    if (isDataValid(true)) {
      setSubmittingTask(true);
      const body = { ...data };
      // Modify target coordinates to match lngLat
      if (!productIsArea) {
        body.target = {
          type: 'Point',
          coordinates: [data.target?.coordinates[1], data.target?.coordinates[0]]
        };
      }
      const token = await userStore.auth0Client?.getTokenSilently();
      publishTaskService(
        body,
        token as string,
        r => {
          if (r.data.task_id) {
            taskStore.addTask(r.data);
            notificationStore.add({
              description: `The task has been created successfully`
            });
            setSubmittingTask(false);
            setShowModal({ preview: false });
            deleteTarget();
            goToProjects();
          } else {
            errorHandler(r.data);
            setSubmittingTask(false);
            setShowModal({ preview: false });
          }
        },
        r => {
          errorHandler(r.data);
          setSubmittingTask(false);
          setShowModal({ preview: false });
        }
      );
    }
  };

  // BlackWing Error Handler
  const errorHandler = (data: any) => {
    for (const [key, errors] of Object.entries(data)) {
      if (key === 'status_details' || key === 'non_field_errors') {
        (errors as []).forEach((error: string) => {
          notificationStore.add({ description: error });
        });
      } else {
        (errors as []).forEach((error: string) => {
          notificationStore.add({ description: `${key}: ${error}` });
        });
      }
    }
  };

  // Redirect to /projects
  const goToProjects = () => {
    let path = '/projects';
    navigate(path);
  };

  return (
    <Layout>
      <Wrapper>
        <MapContainer>
          {!userStore.geofence ? (
            <LoadingWrapper>
              <LoadingIcon />
            </LoadingWrapper>
          ) : (
            <OLMap
              geofence={userStore.detachedGeofence}
              showDraw={showDraw}
              showSearch={data.product !== '-1' && !productIsArea}
              view="tasking"
              onClick={(e: any) => handleSingleMapClick(e)}
              onDraw={(feature: Feature) => handleDraw(feature)}
              onSearch={(latLong: any) => handlePlaceSearch(latLong)}
            >
              {data.coordinates && (
                <div>
                  <Marker coordinates={data.coordinates} />
                  <Popup position={data.coordinates} autoPan>
                    <MapPopup>
                      <span className="close--btn">×</span>
                      <h1 className="popup-title">Point of Interest</h1>

                      <ul className="lat-long">
                        <li>
                          <strong>LAT</strong>: {data.target.coordinates[0]}
                        </li>
                        <li>
                          <strong>LNG</strong>: {data.target.coordinates[1]}
                        </li>
                      </ul>

                      <Button
                        small
                        onlyText
                        onClick={() => deleteTarget()}
                        text="Delete Point of Interest"
                        className="delete-button"
                      />
                    </MapPopup>
                  </Popup>
                </div>
              )}
              {productIsArea && data.target.type && (
                <VectorFeature geojson={data.target} zoom id="target" animate />
              )}
            </OLMap>
          )}
        </MapContainer>
        <Sidebar>
          <FormTitle>Create new task</FormTitle>
          <Form method="POST">
            <FormGroup>
              <Input
                className="form-input"
                error={{ text: 'Please set a valid name' }}
                name="task_name"
                placeholder="Enter your task name..."
                label="Task name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setData({ task_name: e.currentTarget.value })
                }
              />

              <Dropdown
                className="form-input half"
                label="Project"
                defaultOptions={newProject}
                options={taskStore.projects?.map((item: Project) => {
                  return { value: item.name, text: item.name };
                })}
                onChange={selectedOptions => setData({ project_name: selectedOptions[0].value })}
                action={{
                  text: 'Create a project',
                  icon: <PlusIcon />,
                  onClick: () => setShowModal({ newProject: true })
                }}
              />

              <Dropdown
                className="form-input half"
                label="Product"
                options={taskStore.products?.map((item: Product) => {
                  return { value: item.pk.toString(), text: item.name };
                })}
                onChange={selectedOptions => {
                  const prevProductWasArea = data.product !== '-1' && isAreaProductById(data.product, taskStore.products)
                  const currentProductIsArea = isAreaProductById(selectedOptions[0].value, taskStore.products)
                  if (prevProductWasArea !== currentProductIsArea) {
                    deleteTarget()
                    setShowDraw({
                      display: currentProductIsArea,
                      erase: true
                    })
                    if (data.start && data.end && currentProductIsArea && differenceInDays(new Date(data.end), new Date(data.start)) < 14) {
                      setData({ start: null, end: null })
                    }
                  }

                  setData({
                    product: selectedOptions[0].value,
                    product_text: selectedOptions[0].text
                  })
                }}
              />
            </FormGroup>

            {/* AOI */}
            {data.product !== '-1' && productIsArea ? (
              <FormGroup>
                <FormSubtitle>Area of Interest</FormSubtitle>
                <FormText>
                  Draw polygon on the map, upload or paste your area of interest.
                  <br />AOI must be between 50 and 500 km<sup>2</sup> and have at least 4 vertices.
                </FormText>

                {targetArea ? (
                  <FormText className="target-area">
                    Area: {targetArea.toFixed(2)} km<sup>2</sup>
                  </FormText>
                ) : null}

                {showBigAOIDisclaimer ? (
                  <BigAOIDisclaimer>
                    <p>
                      For orders bigger than 500 km<sup>2</sup>, please contact
                      <br />Customer Support at <a href="mailto:tasking@satellogic.com">tasking@satellogic.com</a>
                    </p>
                  </BigAOIDisclaimer>
                ) : null}

                {showFileUpload ? (
                  <FileUpload
                    className="form-input"
                    disabled={false}
                    fileFormats=".json,.geojson,.kml"
                    label="Upload GeoJSON or KML file"
                    name="polygon-file"
                    onChange={async files => {
                      setShowTextarea(false);
                      setClearFileUpload(false);
                      if (files) {
                        setValid({ file: true });
                        const reader = new FileReader();
                        reader.onload = e => {
                          setGeoJSON(e.target?.result);
                        };
                        reader.readAsText(files[0]);
                      } else {
                        setShowTextarea(true);
                        setGeoJSON(undefined);
                      }
                    }}
                    validating={valid.file}
                    clearInput={clearFileUpload}
                  />
                ) : null}

                {showTextarea ? (
                  <Textarea
                    className="form-input"
                    name="geojson"
                    placeholder="Paste GeoJSON or KML here"
                    onChange={e => {
                      setShowFileUpload(false);
                      setGeoJSON(e.currentTarget.value);
                    }}
                    value={valid.geoJSON && data.target.type ? JSON.stringify(data.target) : ''}
                  />
                ) : null}

                {valid.geoJSON && showTextarea ? (
                  <Button
                    className="form-input delete-draw"
                    text="Delete Polygon"
                    onlyText
                    icon={<DeleteIcon size="12" />}
                    onClick={e => deleteTarget()}
                  />
                ) : null}
              </FormGroup>
            ) : null}

            {/* POI */}
            {data.product !== '-1' && !productIsArea ? (
              <FormGroup>
                <FormSubtitle>Point of Interest</FormSubtitle>
                <FormText>
                  Insert below the coordinate (latitude, longitude) of interest for your point
                  capture or select it on the map.
                </FormText>

                <Input
                  className="form-input"
                  name="lat_lng"
                  label="Location"
                  value={
                    !productIsArea
                      ? data.target.coordinates[0]
                        ? coordinatesFormater(data.target.coordinates)
                        : ''
                      : ''
                  }
                  helperText={valid.target ? 'Point of Interest added correctly' : ''}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleBlurPlaceInput(e)}
                />
              </FormGroup>
            ) : null}

            {/* Dates */}
            {data.product !== '-1' ? (
              <FormGroup>
                <InputDateRange
                  className="form-input"
                  clear={data.start === null}
                  name="taskingDates"
                  onChangeStart={(startDate: Date) => setData({ start: `${format(startDate, 'yyyy-MM-dd')}T${startTime}:00Z` })}
                  onChangeEnd={(endDate: Date) => setData({ end: `${format(endDate, 'yyy-MM-dd')}T${endTime}:00Z` })}
                  startLabel="Start Date"
                  endLabel="End Date"
                  startMin={new Date()}
                  maxStartDate={add(new Date(), { months: 3 })}
                  daysAfterStart={productIsArea ? 14 : 1}
                  monthsShown={1}
                  startPlacement="top-start"
                  endPlacement="top-end"
                />

                <Checkbox
                  checked={showTimeInputs}
                  className="form-input"
                  disabled={!data.start || !data.end}
                  label="Set start and end time (UTC)"
                  onChange={(value) => setShowTimeInputs(value)}
                />

                {showTimeInputs ? (
                  <FormGroup>
                    <Input
                      className="form-input half"
                      helperText={minStartTime !== '00:00' ? `Start time must be ${minStartTime} or later` : undefined}
                      label="Start time"
                      max={maxTime}
                      min={minStartTime}
                      name="startHour"
                      type="time"
                      value={startTime}
                      onBlur={e => {
                        setValidStartTime(!!e.target.value)
                        setStartTime(!!e.target.value ? e.target.value : startTime)
                      }}
                    />
                    <Input
                      className="form-input half"
                      helperText={minEndTime !== '00:00' ? `End time must be ${minEndTime} or later` : undefined}
                      label="End time"
                      max={maxTime}
                      min={minEndTime}
                      name="endHour"
                      type="time"
                      value={endTime}
                      onBlur={e => {
                        setValidEndTime(!!e.target.value)
                        setEndTime(!!e.target.value ? e.target.value : endTime)
                      }}
                    />
                  </FormGroup>
                ) : null}
              </FormGroup>
            ) : null}

            {/* Recurrence */}
            {data.product !== '-1' && !productIsArea ? (
              <DropdownWrapper>
                <Dropdown
                  className="form-input"
                  label="Recurrence"
                  options={repeatingPeriods}
                  defaultOptions={repeatingPeriods.filter(item => data.expected_age === item.value)}
                  onChange={selectedOptions => {
                    setData({
                      expected_age: selectedOptions[0].value,
                    });
                  }}
                />
                <InfoIcon fill="#323232" className="pointer" onClick={() => setShowModal({info: true})} />
              </DropdownWrapper>
            ) : null}

            {/* Priority */}
            {false && data.product !== '-1' ? (
              <FormGroup>
                <FormSubtitle>Task Priority</FormSubtitle>
                <FormText>
                  You may decrease the priority to reduce the likelyhood of conflicts with other
                  ongoing tasks.
                </FormText>

                {/* <InputRange
                  className="form-input"
                  id="priority"
                  min={0}
                  max={5}
                  defaultValue={5}
                  onChange={(e: any) => setData({ rating: Number(e.target.value) })}
                  tooltipLabels={{
                    0: 'Lowest',
                    1: 'Low',
                    2: 'Normal',
                    3: 'High',
                    4: 'Highest',
                    5: 'Top'
                  }}
                /> */}
              </FormGroup>
            ) : null}
          </Form>

          <Button
            className="request-task"
            text="Create Task"
            onClick={() => {
              if (isDataValid(true)) setShowModal({ preview: true });
            }}
            disabled={isDataMissing || submittingTask || (showTimeInputs && (!validStartTime || !validEndTime))}
            icon={submittingTask ? <LoadingIcon /> : undefined}
          />
        </Sidebar>
      </Wrapper>
      {showModal.preview && (
        <PreviewTargetModal
          isShown={showModal.preview}
          onAccept={requestTask}
          onClose={() => setShowModal({ preview: false })}
          body={data}
          isArea={isAreaProductById(data.product, taskStore.products)}
          targetArea={targetArea}
        />
      )}
      {showModal.newProject && <ProjectModal
        isShown={showModal.newProject}
        onClose={() => setShowModal({ newProject: false })}
        onClick={name => {
          taskStore.addProject({ name });
          setNewProject([{ value: name, text: name }]);
        }}
      />}
      {showModal.info && <ConfirmationModal
        title="Recurrence"
        text={`Please choose how often you would like us to attempt to capture your location. For example, selecting "Daily" will cause the satellites to attempt to collect an image every day for the duration of your tasking project. Please select "Once" if you would only like a single image capture.`}
        acceptText="Close"
        onConfirm={() => setShowModal({info: false})}
        isShown={true}
        onClose={() => setShowModal({info: false})}
      />}
    </Layout>
  );
});

import { useControl } from 'react-map-gl/maplibre';
import { useEffect, useRef, useState } from 'react';
import { createRoot, Root } from 'react-dom/client';

// Define your custom control
export function InfoControl(props: { position: any, moveEvent: number[] | null, zoomLevel: number | undefined}) {

    const controlRef = useRef<HTMLDivElement | null>(null);
    const rootRef = useRef<Root | null>(null); // To store the root for React 18 rendering

    useControl(
        () => {
            const control = {
                onAdd: () => {
                    const container = document.createElement('div');
                    container.className = 'maplibregl-ctrl maplibregl-ctrl-group';
                    controlRef.current = container;

                    // Create root for React 18 rendering
                    rootRef.current = createRoot(container);

                    // Initial render of the content inside the container
                    renderContent(props);
                    return container;
                },
                onRemove: () => {
                    if (rootRef.current) {
                        rootRef.current.unmount(); // Unmount React component when control is removed
                    }
                    controlRef.current?.parentNode?.removeChild(controlRef.current);
                    controlRef.current = null;
                    rootRef.current = null;
                }
            };
            return control;
        },
        { position: props.position }
    );
    // Update the content of the control when the options prop changes
    useEffect(() => {
        if (controlRef.current && rootRef.current) {            
            renderContent(props); // Re-render when options change            
        }
    }, [props]);

    // Function to render content inside the control's container using createRoot
    const renderContent = (props: any) => {
        if (rootRef.current) {
            rootRef.current.render(
                //@ts-ignore
                <div
                    id="info"
                    style={{
                        width: "380px",
                        display: 'table',
                        height: "1.5rem",                        
                        margin: '0px',                        
                        padding: '2px',
                        border: 'none',
                        borderRadius: '3',
                        fontSize: '12',
                        textAlign: 'left',
                        color: '#222',
                        background: "rgba(200, 200, 200, 0.6)" 
                    }}
                >                                        
                        <div style={{}}>
                        {props.moveEvent && (
                            <>
                            Pointer: latitude {`${props.moveEvent[1].toFixed(3)}, longitude ${props.moveEvent[0].toFixed(3)}`}. 
                            Zoom level: {props.zoomLevel.toFixed(1)}.
                            
                            </>
                        )}
                        </div>                    
                </div>
            );
        }
    };

    return null;
}
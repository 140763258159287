import React from 'react';
import { Button as OriginalButton } from 'components/button'; 
import './AOIModeButtonWrapper.css'; 

const AOIModeButtonWrapper: React.FC<Record<string, any>> = (props) => {
    const { icon, text, ...rest } = props;
  
    return (
      <OriginalButton
        {...rest}
        icon={
          <div className="icon-text-vertical  icon-container">
            {icon}
            <span className="text" style={{ whiteSpace: 'pre-line' }}>{text}</span>
          </div>
        }
        text=""
      />
    );
};

export default AOIModeButtonWrapper;
import styled from '@emotion/styled/macro';

export const MapContainer = styled.main`
  flex: 1 1 auto;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  transition: all 330ms ease-in-out;
  transform: translate(0%, -20px);

  button {
    width: inherit;
  }

  &.move {
    transform: translate(0%, 0%);
  }  
`;

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
`;

export const Title = styled.h1`
  height: 20px;
  background-color: ${(props: any) => props.theme.neutral};
  color: ${(props: any) => props.theme.neutral80};
  ${(props: any) => props.theme.small.styles};
  line-height: 20px;
  pointer-events: none;
  padding: 0 2px;
`;

export const BboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  div {
    gap: 0.5rem;
  }

  label: {
    font-size: 0.7rem;
  }
`;

export const NoResultsStyles = styled.h1`
  padding: 1rem 0;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  &.visible {
    opacity: 1;
  }
`;

export const ButtonsModeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;    
`;

export const BboxMenu = styled.div`
  opacity: 0;
  transition: all 330ms ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &.visible {
    opacity: 1;
    transition: all 900ms ease-in-out;
  }
`;

export const ModeButton = styled.div<{ active?: boolean }>`  
  border-radius: 4px;
  box-shadow: ${props => (props.active ? 'rgba(0, 0, 0, 0.25) -1px 1px 11px 0px' : '')};
  border: ${props => (props.active ? '1px solid #002340' : 'none')};    
`;



export const PopUpContainer = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;

  .link {
    text-decoration: none !important;
  }

  .downloads {
    margin-top: 1rem;
  }

  a {
    text-decoration: none;

    .download {
      height: 2rem;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  li a {
  color: darkblue;
  text-decoration: none;
}

li a:hover {

  color: darkblue;
  font-weight: bold;
  text-decoration: underline;
}
`;

export const LinkButton = styled.button`
  background: none;
  border: none;
  color: #007bff;  /* Link color */
  text-decoration: none;
  font-size: 12px; /* Smaller font size for compactness */
  padding: 0;      /* Remove any padding */
  line-height: 1;  /* Reduces the vertical spacing within the text */
  cursor: pointer;

  &:hover {
    text-decoration: underline; /* Underline on hover for visibility */
    color: #0056b3; /* Optional: Darker color on hover */
  }

  &:focus {
    outline: none; /* Removes the default focus outline */
  }
`;



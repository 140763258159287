import styled from '@emotion/styled/macro';

export const CardContainer = styled.div`
  display: flex;
  height:80px;  
  border: 1px solid #ddd;
  box-sizing: border-box; /* Include padding and border in the element’s total width and height */
  border-radius: 8px;
  overflow: hidden;
  width: 300px;  
  
  margin: 10px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff; /* Ensure background color is set */
`;

export const ThumbnailContainer = styled.div`
  flex-shrink: 0;
  width: auto; /* Fixed width for the thumbnail container */
  height: 80px; /* Fixed height for the thumbnail container */
  overflow: hidden;
  border-right: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 100%; /* Ensure thumbnail fills the container */
  object-fit: cover;
`;

export const Content = styled.div`
  flex: 1; /* Take up remaining space */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px;
  overflow: hidden; /* Ensure content does not overflow */
`;

  

export const Title = styled.button`
  background: transparent; /* No background color */
  border: none;            /* Remove border */
  color: inherit;          /* Inherit text color from parent */
  font: inherit;           /* Inherit font style from parent */
  cursor: pointer;         /* Change cursor to pointer on hover */
  padding: 0;              /* Remove padding */
  margin: 0;               /* Remove margin */
  text-decoration: none;   /* Remove text decoration */

  position: relative;
  padding: 2px;
  font-size: 12px; /* Adjust font size as needed */
  overflow: hidden; /* Hide overflowed content */
  text-overflow: ellipsis; /* Add ellipsis for overflowed content */
  white-space: nowrap; /* Prevent text wrapping */
  max-width: 100%; /* Constrain to parent width */
  cursor: pointer;

  &:hover::after {
    content: attr(data-full-text);
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 12px;
    white-space: normal;
    word-wrap: break-word;
    width: max-content;
    max-width: 300px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
`;


export const Description = styled.p`
  margin: 5px 0;
  font-size: 12px;
  color: #555;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Actions = styled.div`
  //margin-top: 10px;  
  display: flex;
  gap: 10px;  
  align-items: center; /* Align items vertically centered */
  overflow: hidden; /* Ensure actions don't overflow */
`;

export const ActionIcon = styled.div`
  width: 20px;  
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #0056b3;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff;
  }
`;

export const MetadataTableWrapper = styled.div`
    box-sizing: border-box;      
    table {
        font-size: 13px;
        width: 100%;        
        border-collapse: collapse;
    }
    
    td, th {
        word-wrap: break-word; /* Ensures that long words will break to fit within the cell */
        word-break: break-word; /* Allows words to break when they overflow the cell */
        white-space: normal; /* Ensures that text will wrap to a new line when it reaches the edge of the cell */
        overflow-wrap: break-word; /* Ensures text will wrap within the cell */
        hyphens: auto; /* Enables hyphenation to break words */
        padding: 2px 2px;
    }
    th {
        text-align: left;
        padding: 0px 0px 10px;
    }

    .property {
        /* Add any specific styles needed for property cells */
    }
    .value {
        text-align: right;        
    }
`;

export const Date = styled.span`
  font-size: 14px;
  color: #777;
`;

export const SmallTabContainer = styled.div`  
    height: 87%;
    ul > li {
        min-width: 30px;        
        cursor: pointer;
        padding: 10px 3px 10px 3px;        
    }    
     
`;

export const ItemsTable = styled.table`
    width: 100%;
`;

export const CheckboxContainer = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  
  input[type="checkbox"] {
    margin-right: 8px;
    cursor: pointer;
  }
`;
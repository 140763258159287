import React from 'react';
import { useItemContext } from './ItemContext';
import { ActionIcon, Actions, CardContainer, Content, Description, Thumbnail, ThumbnailContainer, Title } from './infoTab.styles';
import { Tooltip } from 'react-tooltip'
import { feature } from '@turf/helpers';
import { mdiDownload, mdiAttachment, mdiCloud, mdiInformationOutline, mdiFormatListBulleted, mdiEye } from '@mdi/js';
import { transformDateTime } from 'views/archive/helpers';
import Icon from '@mdi/react';
import AttachmentPopup from './AttachmentPopup';

interface ItemCardProps {
    item: { id: string, name: string };
}
interface ItemProps {
    feature: FeatureSearch;
    onTitleClick?: (id: string) => void;    
    onViewMetadata?: (id: string) => void;
    onCheckbox?: (id: string) => void;
}

const ItemCard = React.forwardRef<HTMLDivElement, ItemProps>(function ItemCard({ feature, onTitleClick, onViewMetadata }, ref) {
    const { selectedItems, toggleItem, hideItem, unhideItem, hiddenItems } = useItemContext();
    const isSelected = selectedItems.has(feature.id);
    const isHidden = hiddenItems.has(feature.id);

    const handleSelectChange = () => {
        toggleItem(feature.id);
    };

    const handleHideToggle = () => {
        if (isHidden) {
            unhideItem(feature.id);
        } else {
            hideItem(feature.id);
        }
    };

    return  (
        <CardContainer ref={ref}>
            <ThumbnailContainer>
                <Thumbnail src={feature.assets.thumbnail.href} alt={feature.assets.thumbnail.roles.toString()} />
            </ThumbnailContainer>

            <Content>
                <Title title={feature.id} onClick={() => feature.id !== null && onTitleClick && onTitleClick(feature.id)}>{feature.id}</Title>
                <Description>{transformDateTime(feature.properties.datetime)}</Description>
                <Actions>
                    <Tooltip id="item-tooltip" />                                        
                    <AttachmentPopup attachments={feature.assets} id={feature.id} />                                          
                    <ActionIcon onClick={() => feature.id !== null && onViewMetadata && onViewMetadata(feature.id)} data-tooltip-id="item-tooltip" data-tooltip-content="View metadata">
                        <Icon path={mdiInformationOutline} size={0.7} />
                    </ActionIcon>                                        
                    <ActionIcon 
                        onClick={handleHideToggle}
                        data-tooltip-id="item-tooltip" 
                        data-tooltip-content="Hide item on map"
                    >
                        <Icon path={mdiEye} size={0.7} color={isHidden?'gray':undefined}/>
                    </ActionIcon>                    
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={handleSelectChange}
                    />                                                       
                </Actions>
            </Content>
        </CardContainer>
    );
});

export default ItemCard;
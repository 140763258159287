import React, { useEffect } from 'react';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';

import { Router } from './routes';
import { useStores } from 'config/hooks';

import { BigToastList } from 'components/bigtoast';
import { ToastList } from 'components/toast';
import { GlobalStyles } from 'components/global';
import ReactGA from "react-ga4";
import { GA4_TRACKING_ID } from "./config/index"
import { setupAxiosInterceptors } from 'services/axiosInterceptor';

const App: React.FC = observer(() => {
  const { rootStore } = useStores();
  const { rootStore: { userStore, notificationStore, taskStore } } = useStores();

  useEffect(() => {
    setupAxiosInterceptors(userStore);
  }, [userStore]);

  useEffect(() => {
    reaction(
      () => userStore.activeContract,
      (activeContract, previousActiveContract) => {
        let contractWasUpdated: boolean;
        if ((activeContract === undefined) && (previousActiveContract === undefined)) {
          contractWasUpdated = false;
        } else if (activeContract === undefined) {
          contractWasUpdated = false;
        } else if (previousActiveContract === undefined) {
          contractWasUpdated = true;
        } else if (activeContract.id === previousActiveContract.id) {
          contractWasUpdated = false;
        } else {
          contractWasUpdated = true;
        }
        if (contractWasUpdated) {
          userStore.onContractSelection(); // async
          taskStore.onContractSelection(); // async
        }
      }
    );
  }, [rootStore]);

  ReactGA.initialize([
    {
      trackingId: GA4_TRACKING_ID ? GA4_TRACKING_ID : ""
    },
  ]);

  return (
    <React.Fragment>
      <BigToastList notifications={notificationStore.bigNotifications} />
      <GlobalStyles />
      <Router />
      <ToastList notifications={notificationStore.notifications} />
    </React.Fragment>
  );
});

App.displayName = 'App';

export default App;

import styled from '@emotion/styled/macro';

export const AssetButton = styled.button<{ isSelected: boolean }>`
    background-color: ${(props) => (props.isSelected ? '#007bff' : 'white')};
    color: ${(props) => (props.isSelected ? 'black' : 'gray')}; /* Ensure text is visible */
    border: 1px solid ${(props) => (props.isSelected ? '#007bff' : 'transparent')};
    padding: 5px;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => (props.isSelected ? '#0056b3' : '#f0f0f0')}; /* Slight hover effect */
    }
`;

export const Title = styled.h1`
    font-weight: 500

`
import React, { useState, useEffect, useRef } from 'react';

import { Button } from 'components/button';

import {
  HeaderContainer,
  MenuBurger,
  SidebarContainer,
  SidebarMenu,
  SidebarFooter,
  ListItem,
  ListItemAction
} from './menu.styles';

import {
  MenuIcon,
  SatellogicLogo,
  SatelliteIcon,
  ImagesIcon,
  InfoIcon,
  LogoutIcon
} from 'components/icons';

import { classes } from 'config/common';
import { PATHS, SCOPES } from 'config';
import { useStores } from 'config/hooks';
import { ContractConstraint } from 'components/scope';
import { LoadingNotification } from 'components/loadingNotification';
import { AccountBoxOutlined, ArrowDropDownCircleOutlined, AutoStoriesOutlined, ExitToAppOutlined, ManageAccountsOutlined, TravelExploreOutlined } from '@mui/icons-material'
import { ContractsDropdown } from 'components/menu/components/contractsdropdown/contractsdropdown';

export const Menu = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const {
    rootStore: { userStore }
  } = useStores();

  const handleLogout = () => {
    userStore.logout();
  };

  const menuRef = useRef<HTMLDivElement>(null);
  const menuBurgerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        menuBurgerRef.current &&
        !menuBurgerRef.current.contains(event.target)
      ) {
        setMenuIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <React.Fragment>      
      <HeaderContainer>
        <div className="menuWrapper" style={{ display: "flex", flexDirection: "row", width: "500px" }}>
          <MenuBurger ref={menuBurgerRef}>
            <Button
              icon={<MenuIcon />}
              onClick={() => setMenuIsOpen(!menuIsOpen)}
              clearIcon
              onlyIcon
            />
          </MenuBurger>
          <SatellogicLogo />
          <span style={{ display: "flex", "flex": 1 }} />
        </div>

        <LoadingNotification />

        <div style={{ width: "500px", display: "grid", justifyContent:"right" }}>
          <Button
            small
            icon={<ArrowDropDownCircleOutlined />}
            onClick={() => userStore.setActiveContract(undefined)}
            text={userStore.activeContract ? userStore.activeContract.name : ""}
          />

        </div>
      </HeaderContainer>

      <SidebarContainer ref={menuRef} className={classes({ isOpen: menuIsOpen })}>
        <SidebarMenu>
          <ContractConstraint requireTaskingPermission={true}>
            <ListItem to={PATHS.PROJECTLIST} className="menu-item">
              <SatelliteIcon /> My Tasks
            </ListItem>
            <ListItem to={PATHS.IMAGES} className="menu-item">
              <ImagesIcon /> My Captures
            </ListItem>
          </ContractConstraint>
          <ContractConstraint requireArchivePermission={true}>
            <ListItem to={PATHS.ARCHIVE} className="menu-item">
              <TravelExploreOutlined /> Archive Explorer
            </ListItem>
          </ContractConstraint>
        </SidebarMenu>
        <SidebarFooter>
          <ListItem to="" className="menu-item">
            {process.env.REACT_APP_VERSION || 'Development version'}
          </ListItem>
          <ListItem to="/user_guide.pdf" className="menu-item" target="_blank">
            <AutoStoriesOutlined className="guide_icon" /> User Guide
          </ListItem>
          <ListItem to="https://console.platform.satellogic.com/myprofile" className="menu-item" target="_blank">
            <ManageAccountsOutlined /> My profile
          </ListItem>
          <ListItemAction onClick={handleLogout} className="menu-item">
            <ExitToAppOutlined /> Logout
          </ListItemAction>
        </SidebarFooter>
      </SidebarContainer>
    </React.Fragment>
  );
};

Menu.displayName = 'Menu';

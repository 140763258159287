// GaiaIframe.styles.ts
import styled from '@emotion/styled/macro';

export const GaiaContainer = styled.div`
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 1000;
`;

export const ToggleButton = styled.button`
  position: absolute;
  bottom: 100%;
  right: 0;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 1001;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #0056b3;
  }
`;
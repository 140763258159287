import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { clone } from 'mobx-keystone';

import { useStores } from 'config/hooks';
import { Dropdown } from 'components/dropdown';
import { Checkbox } from 'components/checkbox';
import { ConfirmationModal } from '../confirmationmodal';


export const ContractSelectionModal = observer(() => {

  const [defaultContract, setDefaultContract] = useState<boolean>(false)
  const [selectedContract, setSelectedContract] = useState<string>('')

  const {
    rootStore: { userStore }
  } = useStores();

  const handleSubmit = (callback?: () => void) => {
    const contract =  userStore.contracts?.find((contract: { id: any; }) => contract.id === selectedContract);
    if (!!contract) {
      userStore.setActiveContract(clone(contract));
      if (defaultContract){
        userStore.setDefaultContract(clone(contract))
      }else{
        userStore.removeDefaultContract()
      }
    }
    if(callback) callback();
  };

  const dropdownOptions = userStore.contracts?.map((item: { id: any; name: any; }) => ({
    value: item.id,
    text: item.name,
  }));

  return (
    (!userStore.activeContract) ? (
      <ConfirmationModal
        isShown={!userStore.activeContract}
        title={'Contract selection'}
        acceptText={'Select contract'}
        onConfirm={handleSubmit}
        onClose={() => { }}
      >

        Your user can operate on behalf of multiple contracts. Please select one to continue.
        <br />
        <br />
        You can always change the contract you are using on the top dropdown.

        {dropdownOptions &&
          <div>
            <br />
            <Dropdown
              label={!userStore.activeContract ? "Select a contract" : "Contract"}
              options={dropdownOptions}
              onChange={(item) => setSelectedContract(item[0].value)}
              multiple={false}  // Change to true if you want multiple selection
            />
            <br />
            <Checkbox
              label={'Make this my default contract'}
              onChange={(value) => setDefaultContract(value)}
              checked={defaultContract}
            />
          </div>
        }

      </ConfirmationModal >
    ) : null
  )
});

ContractSelectionModal.displayName = 'ContractSelectionModal';

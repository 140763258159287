import axios from 'axios';
import { ARCHIVE_URI } from 'config';

import { PolygonArray } from 'typings';

export const getCollections = async (
  token: string | undefined,
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  axios
    .get(`${ARCHIVE_URI}/collections`, {
      headers: { authorizationToken: `Bearer ${token}` }
    })
    .then((response: any) => {
      onSuccess(response);
    })
    .catch((error: any) => {
      onError(error.response ? error.response : error);
    });
};

interface StacSearchRequest {
  limit: number;
  collections: string[];
  datetime: string;
  token: string;
  bbox?: number[];
  polygon?: PolygonArray;
}

export const loadMoreItems = async (
  usertoken: string | undefined,
  requestBody: StacSearchRequest,
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  axios
    .post(`${ARCHIVE_URI}/search/`, requestBody, {
      headers: { authorizationToken: `Bearer ${usertoken}` }
    })
    .then((response: any) => {
      onSuccess(response);
    })
    .catch((error: any) => {
      onError(error.response ? error.response : error);
    });
};

export const fetchItemDataByOutcomeId = async (
  token: string | undefined,
  startDate: string,
  endDate: string,
  collections: string[],
  bbox: number[] | null,
  polygon: PolygonArray | null,
  limitSearchAmount: string,
  outcomeID: string,
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  const params: any = {
    limit: parseInt(limitSearchAmount),
    collections: collections,
    datetime: `${startDate}/${endDate}`,
    'filter-lang': 'cql2-json',
    filter: {
      'filter-lang': 'cql2-json',
      filter: {
        op: 'and',
        args: [
          {
            op: '=',
            args: [
              {
                property: 'satl:outcome_id'
              },
              outcomeID
            ]
          }
        ]
      }
    }
  };

  if (polygon) {
    params.polygon = polygon;
  } else if (bbox) {
    params.bbox = bbox;
  }


  axios
    .post(`${ARCHIVE_URI}/search/`, params, {
      headers: { authorizationToken: `Bearer ${token}` }
    })
    .then((response: any) => {
      onSuccess(response);
    })
    .catch((error: any) => {
      onError(error.response ? error.response : error);
    });
};

export const searchCollection = async (
  token: string | undefined,
  params: any
): Promise<StacSearchResponse> => {
  const {
    startDate,
    endDate,
    collections,
    limitSearchAmount,
    geometry,
    filter,
  } = params;
  
  const reqBody: any = {
    limit: parseInt(limitSearchAmount),
    collections: collections,
    sortby: [{ field: 'properties.datetime', direction: 'desc' }]
  };

  if (!startDate && !endDate){
    // Do not include it at all, double ../.. is not allowed in stac.      
  }else{
    reqBody['datetime'] = `${startDate?startDate:".."}/${endDate?endDate:".."}`
  }

  if (filter) {
    reqBody['filter-lang'] = 'cql2-json';
    reqBody['filter'] = filter;
  }

  if (!!geometry) {
    reqBody['intersects'] = geometry;
  }

  return new Promise((resolve, reject) => {
  axios
    .post(`${ARCHIVE_URI}/search/`, reqBody, {
      headers: { authorizationToken: `Bearer ${token}` }
    })
    .then((response: any) => {
      resolve(response.data);
    })
    .catch((error: any) => {
      // Call the onError callback
      if (params.onError) params.onError(error);
      // Reject the promise with the error
      reject(error);
    });
  })
};


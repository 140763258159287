
import { polygon } from '@turf/turf';
import { Feature } from '@turf/helpers';
import { MapLayerMouseEvent } from 'react-map-gl';

let currentCanvasCallback: ((ev: any) => void) | null = null;

const createCallback = (newPolyCallback: (poly: Feature | undefined) => void) => (ev: any) => {    
    const map = ev.target;
    const bounds = map.getBounds();
    const poly = polyFromBounds(bounds);
    
    if (newPolyCallback) newPolyCallback(poly);
}

export const currentCanvasSetup = (map: mapboxgl.Map, newPolyCallback: (poly: Feature | undefined) => void) => {        
    currentCanvasCallback = createCallback(newPolyCallback);    
    map.on('zoomend', currentCanvasCallback);
    map.on('move', currentCanvasCallback);

    currentCanvasCallback({ target: map } as any); // Manually trigger it to init with initial map
};

export const currentCanvasTeardown = (map: mapboxgl.Map) => {        
    if (currentCanvasCallback) {
        map.off('zoomend', currentCanvasCallback);
        map.off('move', currentCanvasCallback);
        currentCanvasCallback = null;
    }    
}

const polyFromBounds = (bounds: any) => {

    const southwest = bounds.getSouthWest();
    const northeast = bounds.getNorthEast();

    const boundsArray = [
        [southwest.lng, southwest.lat],
        [northeast.lng, southwest.lat],
        [northeast.lng, northeast.lat],
        [southwest.lng, northeast.lat],
        [southwest.lng, southwest.lat] // Closing the polygon
    ];
    const aoiPoly = polygon([boundsArray]);
    
    return aoiPoly;

    
}

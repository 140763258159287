import axios from 'axios';

export const setupAxiosInterceptors = (userStore: any) => {
  axios.interceptors.request.use(
    (config) => {      
      const activeContractId = userStore.activeContract?.id;
      
      if (activeContractId) {
        config.headers['X-Satellogic-Contract-Id'] = activeContractId;
      }

      return config;
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

// import { defaultTheme } from 'themes/default';
import { toJS } from 'mobx';
import { fromLonLat } from 'ol/proj';
import area from '@turf/area';
import { convertArea, polygon as turfPolygon } from '@turf/helpers';
import { Product, Task } from 'typings';
import { encode } from '@googlemaps/polyline-codec';

export type Dictionary<T = string> = {
  [index: string]: T;
};

export function map<T>(array: Array<T>, mapFn: (t: T, i?: number) => JSX.Element) {
  if (!array || !Array.isArray(array)) {
    return null;
  }
  return array.map(mapFn);
}

type ClassCondition = { [key: string]: boolean | undefined };
type ClassType = undefined | string | string[] | ClassCondition;

export const classNames = (...args: ClassType[]): string => {
  const classes: string[] = [];

  for (let i = 0; i < args.length; i++) {
    const arg = args[i];
    if (!arg) continue;

    const argType = typeof arg;

    if (argType === 'string' || argType === 'number') {
      (classes as string[]).push(arg as string);
    } else if (argType === 'object') {
      for (const key of Object.keys(arg)) {
        if ((arg as ClassCondition)[key]) {
          (classes as string[]).push(key);
        }
      }
    }
  }
  return classes.join(' ');
};

export const toggleArray = (item: any, list: Array<any>): Array<any> => {
  const i = list.find(opt => opt === item);
  if (!i) {
    list.push(item);
  } else {
    list.splice(list.indexOf(item), 1);
  }
  return list;
};

export const isTextValid = (text: string, type: string): boolean => {
  switch (type) {
    case 'email':
      return /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(text)
        ? true
        : false;
    case 'password':
      // validate password maybe?
      return true;
    case 'text':
    default:
      return text !== '' ? true : false;
  }
};

export const getFromStorage = (k: string) => {
  return localStorage.getItem(k) || sessionStorage.getItem(k);
};

export const getCookie = (c_name: string) => {
  let c_value: string | null = ' ' + document.cookie;
  let c_start = c_value.indexOf(' ' + c_name + '=');
  if (c_start === -1) {
    c_value = null;
  } else {
    c_start = c_value.indexOf('=', c_start) + 1;
    var c_end = c_value.indexOf(';', c_start);
    if (c_end === -1) c_end = c_value.length;
    c_value = unescape(c_value.substring(c_start, c_end));
  }
  return c_value;
};

export const dateFormater = (date: string, withHour: boolean = true) => {
  const d = new Date(date);
  // Make sure d is actually an instance of Date and is valid, if not, return the date unformatted
  if (d instanceof Date && isFinite(d.getTime())) {
    const isoDate = d.toISOString();
    return withHour
      ? `${isoDate.substr(0, 10)} ${isoDate.substr(11, 5)} UTC`
      : isoDate.substr(0, 10);
  } else {
    return date;
  }
};

export const coordinatesFormater = (coordinates: number[]) => {
  return `${coordinates[0]?.toFixed(6) || '-'}, ${coordinates[1]?.toFixed(6) || '-'}`;
};

export const statusFormater = (status: string) => {
  let _r = '';
  switch (status) {
    case 'in_progress':
      _r = 'In Progress';
      break;

    case 'rejected':
      _r = 'Rejected';
      break;

    case 'pending':
      _r = 'Pending';
      break;

    case 'received':
      _r = 'Received';
      break;

    case 'failed':
      _r = 'Failed';
      break;

    case 'canceled':
      _r = 'Canceled';
      break;

    case 'completed':
      _r = 'Completed';
      break;
    case 'planning':
      _r = 'Planning';
      break;
    case 'partially_completed':
      _r = 'Partially Completed';
      break;
    default:
      break;
  }
  return _r;
};

export const getPreviewImage = (
  coordinates: number[],
  options?: { width?: number; height?: number; zoom?: number },
  geojson?: any
) => {
  let area = undefined;
  if (geojson && geojson.type !== 'MultiPolygon') {
    let cleanGeojsonCoordinates = toJS(geojson).coordinates[0].map((coordinate: number[]) => [
      Number(coordinate[0].toFixed(2)),
      Number(coordinate[1].toFixed(2))
    ]);
    area = encodeURIComponent(
      JSON.stringify({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              fill: '#59C8E6',
              'fill-opacity': 0.25,
              stroke: '#00B6DA',
              'stroke-width': 2
            },
            geometry: {
              type: 'Polygon',
              coordinates: [cleanGeojsonCoordinates]
            }
          }
        ]
      })
    );
  }
  return `https://api.mapbox.com/styles/v1/mapbox/light-v11/static/${area ? (area.length <= 5000 ? `geojson(${area})/` : '') : ''
    }${coordinates[0]},${coordinates[1]},${options?.zoom || '5'},0,4/${options?.width || '1280'}x${options?.height || '1280'
    }?access_token=pk.eyJ1Ijoibmljb2xhc3NhbnRvcyIsImEiOiJjbGM5YmNqazEwbHI0M3ZwOHd5djZ1NzI2In0.8ey9Tv8rV5jTaVuWVoMqPg`;
};

export const getPreviewImageForAOI = (
  coordinates: any | number[][],
  geoJSONType: string | undefined,
  areaCenter?: { lat?: number; lng?: number }
) => {
  if (geoJSONType !== 'Polygon')
    return `center=${areaCenter?.lng},${areaCenter?.lat}&zoom=8&size=350x144`; //if MultiPolygon or FeatureCollection, draw as a POI like

  // @ts-ignore
  const filteredCoordinates = coordinates?.map(subArray => {
    subArray.slice(0, -1); //delete the last item in the polygon
    const [first, second] = subArray;
    return [second, first]; //switch values from (lng, lat) to (lat, lng)
  });

  if (filteredCoordinates?.length && filteredCoordinates.length <= 200) {
    return `l=${encode(filteredCoordinates, 6)},00CCE6,3&size=350x144&zoom=2`;
  } else if (filteredCoordinates.length && filteredCoordinates.length > 200) {
    //Polygon too big, generates an URL too long and an error in the request
    return `center=${areaCenter?.lng},${areaCenter?.lat}&zoom=8&size=350x144`;
  }
};

export const parseParameters = (opt: any) => {
  delete opt.token;
  return opt;
};

export const isPointInsidePolygon = (latLng: Array<number>, polygons: any[]) => {
  let lat = latLng[0];
  let lng = latLng[1];
  let inside = false;

  // Calculations taken from https://observablehq.com/@tmcw/understanding-point-in-polygon
  polygons.forEach(polygon => {
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      let lati = polygon[i][0];
      let lngi = polygon[i][1];
      let latj = polygon[j][0];
      let lngj = polygon[j][1];

      let intersect =
        // eslint-disable-next-line
        lngi > lng !== lngj > lng && lat < ((latj - lati) * (lng - lngi)) / (lngj - lngi) + lati;
      if (intersect) inside = !inside;
    }
  });

  return inside;
};

export const isGeometryInsidePolygon = (geometryCoordinates: any, polygonCoordinates: any) => {
  for (let i = 0; i < geometryCoordinates.length; i++) {
    if (!isPointInsidePolygon(geometryCoordinates[i], polygonCoordinates)) return false;
  }
  return true;
};

export const getProductById = (id: number | string, l: Product[]) => {
  if (typeof id === 'string') id = parseInt(id);
  return l.find(product => id === product.pk);
};

export const isAreaProductById = (id: number | string, l: Product[]) => {
  if (typeof id === 'string') id = parseInt(id);
  return l?.find(product => id === product.pk)?.is_area;
};

export const equalArrays = (a: Array<any>, b: Array<any>) => {
  if (a.length !== b.length) return false;
  const uniqueValues: any = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter(e => e.value === v.value).length;
    const bCount = b.filter(e => e.value === v.value).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

export const getTaskCoordinates = (task: Task) => {
  return task.areaCenter
    ? fromLonLat([task.areaCenter.lng, task.areaCenter.lat])
    : task.target
      ? fromLonLat([task.target.coordinates[0], task.target.coordinates[1]])
      : null;
};

export const repeatingPeriods = [
  { value: '', text: 'Once' },
  { value: '01 00:00:00', text: 'Daily' },
  { value: '03 00:00:00', text: 'Twice a Week' },
  { value: '07 00:00:00', text: 'Weekly' },
  { value: '14 00:00:00', text: 'Twice a Month' },
  { value: '30 00:00:00', text: 'Monthly' }
];

export const recurrenceValues = [
  { value: '', text: 'Once' },
  { value: 'None', text: 'Once' },
  { value: null, text: 'Once' },
  { value: '01 00:00:00', text: 'Daily' },
  { value: '1 day, 0:00:00', text: 'Daily' },
  { value: '03 00:00:00', text: 'Twice a Week' },
  { value: '3 days, 0:00:00', text: 'Twice a Week' },
  { value: '07 00:00:00', text: 'Weekly' },
  { value: '7 days, 0:00:00', text: 'Weekly' },
  { value: '14 00:00:00', text: 'Twice a Month' },
  { value: '14 days, 0:00:00', text: 'Twice a Month' },
  { value: '30 00:00:00', text: 'Monthly' },
  { value: '30 days, 0:00:00', text: 'Monthly' }
];

export const transformAreaToKms = (area: number) => {
  return Math.round((area / 1000000) * 100) / 100;
};

export const getPolygonArea = (coordinates: any) => {
  return convertArea(area(turfPolygon(coordinates)), 'meters', 'kilometers');
};

export const getTaskById = (tasks: Task[], taskId: string) => {
  return tasks?.find((task: Task) => task.task_id === taskId);
};

export const getTasksByProjectName = (projectName: string, tasks: Task[]) => {
  return tasks?.filter((task: Task) => task.project_name === projectName);
};


// Function to calculate the total area of a FeatureCollection
export const calculateTotalArea = (featureCollection: { features: any[]; }) => {
  let totalArea = 0;

  // Loop through each feature (polygon) in the collection
  featureCollection.features.forEach(feature => {
    const polygon = turfPolygon(feature.geometry.coordinates);

    // Add the area of the current polygon to the total
    const polArea = transformAreaToKms(area(polygon));
    totalArea += polArea;
  });

  return totalArea;
};
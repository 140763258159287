import styled from '@emotion/styled/macro';
import { Tooltip } from 'react-tooltip';

export const PopupContainer = styled.div`
  position: relative;
  display: inline-block;
  z-index: 0;
`;

export const PopupButton = styled.button`
  
  background: none;
  border: none;
  cursor: pointer;   
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #0056b3;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff;
  }
`;

export const PopupContent = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  background: #fff;
  border: 1px solid #ddd;
  padding: 2px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 99999999;
  max-width: 300px;
`;

export const AttachmentList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const AttachmentItem = styled.li`
  margin: 0;
  padding: 2px;
  padding-bottom: 2px; /* Smaller padding between items */
`;

export const AttachmentLink = styled.a`
  text-decoration: none;
  font-size: 12px; /* Smaller font size for compactness */
  color: #007bff;  /* Link color */
  padding: 0;      /* Remove any padding */
  line-height: 1;  /* Reduces the vertical spacing within the text */

  &:hover {
    text-decoration: underline; /* Underline on hover for visibility */
  }
`;

export const CustomTooltip = styled(Tooltip)`
  padding: 5px 10px; /* Control padding inside the tooltip */
  font-size: 12px;   /* Control the font size */
  max-width: 200px;  /* Set a max-width for the tooltip */
  white-space: nowrap; /* Prevent text from wrapping */
  background-color: #333; /* Background color */
  color: #fff;       /* Text color */
  border-radius: 4px; /* Round corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  ul > li {
    padding: 0px;
  }

`;

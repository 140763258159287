import DOMPurify from 'dompurify';
//@ts-ignore
import StacFields from '@radiantearth/stac-fields';

import archiveStore from 'stores/archiveStore';
import { Container, GroupContainer, GroupTitle, SpecContainer, SpecItem, Table, Tbody, Td, Th, Thead, Title } from "./MetadataFrame.styles";

interface MyComponentProps {
    id: string | null;
}



// Define types
type Spec = {
    label: string;
    explain?: string;
    unit?: string;
    format?: string;
    summary?: boolean | string;
};

type Property = {
    label: string;
    value: any;
    formatted: string;
    items: any[] | null;
    itemOrder: any[];
    spec: Spec;
};

type Group = {
    extension: string;
    label: string;
    properties: { [key: string]: Property };
};

type Props = {
    data: Group[];
};

const MetadataFrame: React.FC<MyComponentProps> = ({ id }) => {

    const item = archiveStore.activeProductData?.features.filter(feature => feature.id == id)[0]
    let metadata: {} | null;
    if (item) {
        StacFields.Registry.addMetadataField('satl:shadow_cover', {
            label: "Shadow",
            formatter: (value: number) => value + " %"
        });

        const metadata = StacFields.formatItemProperties(item, (key: string, path: string) => key !== 'eo:bands');
        return (
            <Container>
                <Title>{item?.id}</Title>

                {
                    metadata.map((group: Group, index: number) => (
                        <GroupContainer key={index}>
                            <GroupTitle>{group.label || 'Default Group'}</GroupTitle>
                            <Table>
                                <Thead>
                                    <tr>
                                        <Th>Label</Th>
                                        <Th>Value</Th>
                                    </tr>
                                </Thead>
                                <Tbody>
                                    {Object.entries(group.properties).map(([key, property]) => (
                                        <tr key={key}>
                                            <Td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(property.label) }} />
                                            <Td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(property.formatted) }} />

                                        </tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </GroupContainer>
                    ))
                }

            </Container>
        );
    }else{
        return ""
    }

};
export default MetadataFrame;
import React from 'react'
import { BigToast } from './bigtoast'
import { useStores } from 'config/hooks';

import { List } from './bigtoast.styles'

interface Props {
  notifications: any[]
}

export const BigToastList = ({notifications}: Props) => {

  const { rootStore: { notificationStore } } = useStores();

  return (
    <List>
      {notifications?.map((noti: any, k: number) =>
        <BigToast
          description={noti.description}
          actionText="dismiss"
          url={noti.url}
          onClick={() => notificationStore.remove(noti.id)}
          key={k}
        />
      )}
    </List>
  )
}


type CardRefs = {
    [key: string]: HTMLDivElement | null;
};

class ArchiveResultsStore {
    cardRefs: CardRefs = {};
    
    setCardRef(outcomeId: string, element: HTMLDivElement | null) {        

        if (element) {
            this.cardRefs[outcomeId] = element;
        } else {
            delete this.cardRefs[outcomeId];
        }
    }

    scrollToCard(outcomeId: string) {
        const card = this.cardRefs[outcomeId];
        if (card) {
            
        }
    }
}


const archiveResultsStore = new ArchiveResultsStore();
export default archiveResultsStore;
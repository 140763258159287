import styled from '@emotion/styled/macro';
import { Button } from 'components/button';

export const ResultsList = styled.div`  
  display: flex;
  gap: 1rem;
  height: 100%;
  width:100%;
  flex-direction: column;
`;
export const CardItem = styled.div<{ isSelected?: boolean }>`
  width: 95%;
  font-size: 14px;
  border: 1px solid ${(props: any) => (props.isSelected ? 'none' : props.theme.neutral20)};
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  background-color: ${props => (props.isSelected ? '#54B3DB70' : '#fff')};
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
  }
  :hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    cursor: pointer;
    background-color: #f2f2f2;
  }
`;

export const Row = styled.div`
  width:90%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;


export const TruncatedDiv = styled.div`
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  &:focus,
  &:focus:after,
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  width:100%;
`;

export const Thumbnail = styled.img`  
  height: 50px;  
  max-width: 30px;
  margin-right: 10px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;  
`;

// Styled component for the scroll button
export const ScrollButton = styled.button`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  
`;
import { featureCollection } from '@turf/helpers';
import { AllGeoJSON } from '@turf/turf';
import { Feature, FeatureCollection, Geometry, GeometryCollection } from 'geojson';
import dayjs from "dayjs";

export const draw_styles = [
    {
        'id': 'gl-draw-polygon-fill-inactive',
        'type': 'fill',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static']
        ],
        'paint': {
            'fill-color': '#3bb2d0',
            'fill-outline-color': '#3bb2d0',
            'fill-opacity': 0.0
        }
    },
    {
        'id': 'gl-draw-polygon-fill-active',
        'type': 'fill',
        'filter': ['all', ['==', 'active', 'true'],
            ['==', '$type', 'Polygon']
        ],
        'paint': {
            'fill-color': '#fbb03b',
            'fill-outline-color': '#fbb03b',
            'fill-opacity': 0.0
        }
    },
    {
        'id': 'gl-draw-polygon-midpoint',
        'type': 'circle',
        'filter': ['all', ['==', '$type', 'Point'],
            ['==', 'meta', 'midpoint']
        ],
        'paint': {
            'circle-radius': 3,
            'circle-color': '#fbb03b'
        }
    },
    {
        'id': 'gl-draw-polygon-stroke-inactive',
        'type': 'line',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': '#3bb2d0',
            'line-width': 2
        }
    },
    {
        'id': 'gl-draw-polygon-stroke-active',
        'type': 'line',
        'filter': ['all', ['==', 'active', 'true'],
            ['==', '$type', 'Polygon']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': '#fbb03b',
            'line-dasharray': [0.2, 2],
            'line-width': 2
        }
    },
    {
        'id': 'gl-draw-line-inactive',
        'type': 'line',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'LineString'],
            ['!=', 'mode', 'static']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': '#3bb2d0',
            'line-width': 2
        }
    },
    {
        'id': 'gl-draw-line-active',
        'type': 'line',
        'filter': ['all', ['==', '$type', 'LineString'],
            ['==', 'active', 'true']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': '#fbb03b',
            'line-dasharray': [0.2, 2],
            'line-width': 2
        }
    },
    {
        'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-color': '#fff'
        }
    },
    {
        'id': 'gl-draw-polygon-and-line-vertex-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
        ],
        'paint': {
            'circle-radius': 3,
            'circle-color': '#fbb03b'
        }
    },
    {
        'id': 'gl-draw-point-point-stroke-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-opacity': 1,
            'circle-color': '#fff'
        }
    },
    {
        'id': 'gl-draw-point-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static']
        ],
        'paint': {
            'circle-radius': 3,
            'circle-color': '#3bb2d0'
        }
    },
    {
        'id': 'gl-draw-point-stroke-active',
        'type': 'circle',
        'filter': ['all', ['==', '$type', 'Point'],
            ['==', 'active', 'true'],
            ['!=', 'meta', 'midpoint']
        ],
        'paint': {
            'circle-radius': 7,
            'circle-color': '#fff'
        }
    },
    {
        'id': 'gl-draw-point-active',
        'type': 'circle',
        'filter': ['all', ['==', '$type', 'Point'],
            ['!=', 'meta', 'midpoint'],
            ['==', 'active', 'true']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-color': '#fbb03b'
        }
    },
    {
        'id': 'gl-draw-polygon-fill-static',
        'type': 'fill',
        'filter': ['all', ['==', 'mode', 'static'],
            ['==', '$type', 'Polygon']
        ],
        'paint': {
            'fill-color': '#404040',
            'fill-outline-color': '#404040',
            'fill-opacity': 0.0
        }
    },
    {
        'id': 'gl-draw-polygon-stroke-static',
        'type': 'line',
        'filter': ['all', ['==', 'mode', 'static'],
            ['==', '$type', 'Polygon']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': '#404040',
            'line-width': 2
        }
    },
    {
        'id': 'gl-draw-line-static',
        'type': 'line',
        'filter': ['all', ['==', 'mode', 'static'],
            ['==', '$type', 'LineString']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': '#404040',
            'line-width': 2
        }
    },
    {
        'id': 'gl-draw-point-static',
        'type': 'circle',
        'filter': ['all', ['==', 'mode', 'static'],
            ['==', '$type', 'Point']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-color': '#404040'
        }
    }
]

export const findMinMaxDates = (response: StacSearchResponse) => {
    if (response.features.length === 0) {
        return { minDate: null, maxDate: null };
    }

    // Extract dates from the features
    const dates = response.features.map(feature =>
        new Date(feature.properties.datetime)
    );

    // Find the minimum and maximum dates
    const minDate = new Date(Math.min(...dates.map(date => date.getTime())));
    const maxDate = new Date(Math.max(...dates.map(date => date.getTime())));

    return { minDate, maxDate };
};


export const isFeature = (obj: any): obj is Feature<Geometry> => {
    return obj && obj.type === 'Feature' && obj.geometry;
};

export const isFeatureCollection = (obj: any): obj is FeatureCollection<Geometry> => {
    return obj && obj.type === 'FeatureCollection' && Array.isArray(obj.features);
};

// Function to return all unique geometry types in the GeoJSON object
export const getAllGeometryTypes = (geojson: FeatureCollection<Geometry>): string[] => {
    const geometryTypes = new Set<string>(); // Use a Set to store unique types    

    geojson.features.forEach(feature => {
        geometryTypes.add(feature.geometry.type);
    });

    return Array.from(geometryTypes); // Convert the Set back to an array
}


export const convertToMultiPoint = (featureCollection: FeatureCollection): Feature => {
    const points = featureCollection.features
        .filter(feature => feature.geometry.type === 'Point')
        .map(feature => {
            if (feature.geometry.type === 'Point') {
                return feature.geometry.coordinates;
            }
            return null
        })
        .filter((coordinates): coordinates is number[] => coordinates !== null); // Filter out nulls

    return {
        type: 'Feature',
        geometry: {
            type: 'MultiPoint',
            coordinates: points
        },
        properties: {} // Optionally merge properties if needed
    };
}


export const convertToMultiPolygon = (featureCollection: FeatureCollection): Feature => {
    const polygons = featureCollection.features
        .filter(feature => feature.geometry.type === 'Polygon')
        .map(polygonFeature => {
            // Use type assertion to ensure the geometry is of type Polygon
            if (polygonFeature.geometry.type === 'Polygon') {
                return polygonFeature.geometry.coordinates;
            }
            return null; // Safeguard in case it's not a Polygon
        })
        .filter((coordinates): coordinates is number[][][] => coordinates !== null); // Filter out nulls

    return {
        type: 'Feature',
        geometry: {
            type: 'MultiPolygon',
            coordinates: polygons
        },
        properties: {} // Optionally merge properties if needed
    };
}

/**
 * https://day.js.org/docs/en/manipulate/utc-offset
 * @param {Date} date
 */
export function toCurrentOffset(date: Date) {

    // initialize
    let result = dayjs(date)

    // remove date offset
    result = result.subtract(result.utcOffset(), 'minutes')

    return result.toDate()
}

/**
 * https://day.js.org/docs/en/manipulate/utc-offset
 * @param {Date} date
 */
export function toDateOffset(date: Date) {

    // initialize
    let result = dayjs(date)

    // add current offset of today
    result = result.add(result.utcOffset(), 'minutes')

    return result.toDate()
}
import { action, makeAutoObservable, observable, runInAction } from 'mobx';

class LoadingNotificationStore {
  loading: boolean = false;
  noResults: boolean = false;
  text: string = 'Loading results, almost there...';
  @observable errorMessage: string | null = null;
  private errorMessageTimeout: NodeJS.Timeout | null = null;
  modalIsOpen: boolean = false;


  constructor() {
    makeAutoObservable(this, {
      setLoading: action,
      setNoResults: action,
      setText: action,
      setModal: action
    });
  }

  setModal(value: boolean){
    this.modalIsOpen = value;
  }
  setLoading(value: boolean) {
    this.loading = value;
  }

  setNoResults(value: boolean) {
    this.noResults = value;

    if (value) {
      setTimeout(() => {
        runInAction(() => {
          this.noResults = false;
        });
      }, 5000);
    }
  }

  setText(value: string) {
    this.text = value;
  }
  @action
  setErrorMessage(error: string | null) {
    this.errorMessage = error;
    //so the error message will always remain visible for at least 5 seconds, even if you trigger the error action multiple times in quick succession
    if (this.errorMessageTimeout) {
      clearTimeout(this.errorMessageTimeout);
      this.errorMessageTimeout = null;
    }

    if (error) {
      this.errorMessageTimeout = setTimeout(() => {
        runInAction(() => {
          this.errorMessage = null;
        });
      }, 5000);
    }
  }
}

const loadingNotificationStore = new LoadingNotificationStore();
export default loadingNotificationStore;

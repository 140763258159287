export const API_URI = process.env.REACT_APP_API_URI;

export const BLACKWING_API_URI = `${API_URI}/tasking`;

export const TELLURIC_URI = `${API_URI}/telluric`;

export const GEODAS_URI = `${API_URI}/geodas`;

export const AUDIENCE_URI = process.env.REACT_APP_AUDIENCE_URI;

export const CZML_URI = process.env.REACT_APP_CZML_URI || 'satellogic.czml';

export const GA_CODE = process.env.REACT_APP_GA_CODE || 'UA-169642923-1';

export const FRONTEND_URI = process.env.REACT_APP_FRONT_URI || "https://geodas.satellogic.com";

export const ARCHIVE_URI = process.env.REACT_APP_ARCHIVE_API_URL

export const TILER_URI = process.env.REACT_APP_TILER_API_URL

export const PRESIGNED_OR_S3  = process.env.REACT_APP_USE_TILER_WITH_PRESIGNED_OR_S3

export const GA4_TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID

export const PATHS = {
  ROOT: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  IMAGES: '/my-images',
  ACCOUNT: '/account',
  TASKING: '/tasking',
  PLAYGROUND: '/playground',
  PROJECTLIST: '/projects',
  PROJECTLISTMAP: '/projects/map',
  ARCHIVE: '/archive'
};

export const SCOPES = {
  TASKING: 'api:tasking',
  DELIVERY: 'api:delivery',
  ALEPH: 'api:aleph',
  GAIA: 'gui:gaia',
  ARCHIVE: 'gui:archive',
  ARCHIVE_RASTERS: 'gui:telluric_rasters'
};

// FIXME: The available collection should be defined in the backend
export const ARCHIVE_COLLECTIONS = [
  'api:eobackend',
  'stac_collection:quickview-visual',
  'stac_collection:quickview-toa',
  'stac_collection:l0',
  'stac_collection:l1a',
  'stac_collection:l1d',
  'stac_collection:l1d-sr',
  'stac_collection:l1c',
  'stac_collection:quickview-visual-thumb',
  'temporarily_restricted_area:extended',
  'restricted_area:israel',
]

import React from 'react';
import styled from '@emotion/styled/macro';

interface ProgressBarProps {
    progress: number; // Progress value between 0 and 100
    total: number | undefined
}

const Container = styled.div`
    position: relative;
    width: 100%; /* Adjust as needed */
    height: 30px; /* Adjust as needed */
    background-color: #e0e0e0; /* Background color of the container */
    border-radius: 5px; /* Rounded corners */
    overflow: hidden;
`;

const Bar = styled.div<{ width: number }>`
    position: absolute;
    width: ${props => props.width}%;
    height: 100%;
    background-color: #002340; /* Progress bar color */
    transition: width 0.4s ease; /* Smooth transition */
`;

const Text = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff; /* Text color */
    font-weight: bold;
    font-size: 16px; /* Adjust size as needed */
`;

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, total }) => {
    const progressPercentage = total ? Math.round((progress / total) * 100) : 0;

    return (
        <Container>
            {total ?
                <>
                    <Bar width={progressPercentage} />
                    <Text>{progressPercentage}%</Text>
                </>
                : null
            }

        </Container>
    );
};

export default ProgressBar;
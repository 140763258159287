import React, { FC } from 'react';

import {
  ToastDiv, SimpleDiv, ToastButton, ToastLink, ToastMessage
} from './bigtoast.styles';

import { classes } from 'config/common';

export type Props = {
  /** Unique ID */
  id?: number;
  /** CSS class name */
  className?: string;
  /** Description for toast */
  description: string;
  /** Text for custom action */
  actionText?: string;
  /** url for custom link */
  url?: string;
  /** Close function */
  onClick?: () => void;
  /** Open */
  open?: boolean;
}

export const BigToast: FC<Props> = ({className, description, actionText, url, onClick = () => {}, open = true}) => {

  return (
    <ToastDiv data-testid="toast-div" className={classes({open}, className)}>
      <SimpleDiv>
        <ToastMessage>{description}</ToastMessage>
        {onClick && <ToastButton onClick={onClick}>&nbsp; {actionText}</ToastButton>}
        {url && <ToastLink to={url}>{actionText}</ToastLink> }
      </SimpleDiv>
    </ToastDiv>
  );
}

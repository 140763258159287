import React, { useState, useRef, useEffect } from "react";
import { classes } from 'config/common';
import { Container, TabsHeader, Tab, ActiveTabIndicator, TabContent } from "./tabscontainer.styles";
import { observer } from "mobx-react-lite";
import { Tooltip } from 'react-tooltip'

export interface TabObject {
  label: string;
  content: string | JSX.Element;
  tooltip?: string;
  enabled: boolean;  
}

interface TabsContainerProps {
  className?: string;
  tabs: Array<TabObject>;
  selectedTab: string | undefined;
  setSelectedTab?: (tab: string) => void; // Make setSelectedTab optional
  onTabChange?: (tab: string) => void;
  onTabHover?: (tab: string, e: any) => void;
}

export const TabsContainer = observer((props: TabsContainerProps) => {
  const { className, tabs, selectedTab, setSelectedTab, onTabChange, onTabHover } = props;
  
  const tabIndex = tabs.findIndex(tab => selectedTab === tab.label);

  const refContainer = useRef<HTMLDivElement>(null);
  const refHeader = useRef<HTMLUListElement>(null);

  const [headerHasScroll, setHeaderHasScroll] = useState(false);

  useEffect(() => {
    if (refContainer.current !== null && refHeader.current !== null) {
      setHeaderHasScroll(refContainer.current.scrollWidth < refHeader.current.scrollWidth);
    }
  }, []);

  const noTabs = tabs.length === 1;

  const tabsHeader = (
    <TabsHeader ref={refHeader}>
      {tabs.map(({ label, enabled, tooltip }) => {        
        const selectedTabHandler = () => {
          if (enabled && setSelectedTab) {
            setSelectedTab(label);            
            if (onTabChange) {
              onTabChange(label);
            }
          }
        };
       
        return (                 
          <Tab
            className={classes({ isSelected: selectedTab === label, isDisabled: !enabled })}
            tabCount={tabs.length}
            onClick={selectedTabHandler}
            key={label}              
            onMouseMove={(e) => onTabHover?onTabHover(label, e):null}       
            data-tooltip-id="tab-tooltip"
            data-tooltip-content={tooltip} 
            data-tooltip-delay-show={500}
            >                         
            {label}
          </Tab>
        );
      })}
      <ActiveTabIndicator headerHasScroll={headerHasScroll} tabCount={tabs.length} activeTab={tabIndex} />
    </TabsHeader>
  );
  
  return (
    <>
    <Tooltip id="tab-tooltip" place="bottom"/>   
    <Container className={className} ref={refContainer}>
      {noTabs ? null : tabsHeader}
      <TabContent className={classes({ noTabs })}>
        {tabs[tabIndex] ? tabs[tabIndex].content : tabs[0].content} 
      </TabContent>
    </Container>
    </>
  );
});
TabsContainer.displayName = 'TabsContainer';
import React from 'react'
import { BaseModal } from '../basemodal'

import { LayerItem } from 'components/layers/layers.store'
import { Button } from 'components/button';

import {
  Modal,
  TaskInfo,
  TaskTitle,
  TaskCoordinates,
  TaskContent,
  TaskRow,
  SpanBold,
  RowTitle
} from './unauthorizedmodal.styles'

import { dateFormater, coordinatesFormater } from 'config/helpers'


interface ModalProps {
  errorMessage?: string
  contactEmail?: string
  onLogout: () => void
}


export const UnauthorizedModal = ({ errorMessage, contactEmail, onLogout }: ModalProps) => {
  return (
    <BaseModal isShown={true} hide={() => true}>
      <Modal>
        <TaskInfo>
          <TaskTitle>Access Denied</TaskTitle>
        </TaskInfo>
        <TaskContent>
          <TaskRow className="no-border">
            <RowTitle>{errorMessage}</RowTitle>
          </TaskRow>
          <TaskRow className="no-border">
          <p>
            If you believe this is a mistake and the problem persists, please contact{' '}
            <a href={`mailto:${contactEmail}`}>
              {contactEmail}
            </a>
          </p>
          </TaskRow>
          <TaskRow>
            <Button text={"Logout"} onClick={onLogout}/>
          </TaskRow>
        </TaskContent>
      </Modal>
    </BaseModal>
  );
};

UnauthorizedModal.displayName = 'UnauthorizedModal';

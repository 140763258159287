import styled from '@emotion/styled/macro';

export const Title = styled.h1`
  color: ${(props: any) => props.theme.primary10};
  ${(props: any) => props.theme.heading1.styles};
  line-height: 32px;
  &.transparent {
    color: transparent;
  }
`;

export const SearchForm = styled.form`
  width: 100%;
  max-width: 340px;
  margin-left: 15px;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const CreateButton = styled.button`
  width: 125px;
  height: 40px;
  align-items: center;
  background-color: ${(props: any) => props.theme.primary};
  border: none;
  border-radius: 20px;
  color: ${(props: any) => props.theme.neutral};
  cursor: pointer;
  display: flex;
  ${(props: any) => props.theme.small.styles};
  justify-content: flex-start;
  outline: none;
  padding: 0 10px;
  text-transform: uppercase;
  .plus-icon {
    margin-right: 13px;
  }
  &:hover {
    background-color: ${(props: any) => props.theme.primary20};
  }
`;

export const FilterButton = styled.div`
  align-items: center;
  color: ${(props: any) => props.theme.secondary10};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  line-height: 21px;
  margin-left: 15px;
  transition: color 0.2s ease-in;
  ${(props: any) => props.theme.small.styles};
  .action--icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    &.close--icon {
      width: 9px;
      height: 9px;
      fill: ${(props: any) => props.theme.secondary10};
    }
    path {
      stroke: ${(props: any) => props.theme.secondary10};
      transition: stroke 0.2s ease-in, fill 0.2s ease-in;
    }
  }
  &:hover {
    color: ${(props: any) => props.theme.secondary40};
    .action--icon {
      path {
        stroke: ${(props: any) => props.theme.secondary40};
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const FiltersWrapper = styled.div`
  max-height: 0;
  width: 100%;
  display: flex;
  overflow: hidden;
  opacity: 0;
  margin-top: 0;
  transition: max-height 0.3s ease-in-out, margin-top 0.3s ease-in-out, opacity 0.3s ease-in-out;
  &.show {
    max-height: 100px;
    overflow: visible;
    opacity: 1;
    margin-top: 16px;
  }
  .filterButton {
    margin: 0 12px 0 24px;
  }
`;

export const FiltersContainer = styled.div`
  max-width: 70%;
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
  .idFilter {
    width: 420px;
    height: 40px;
    margin-right: 24px;
    input {
      padding: 10px 12px;
    }
  }
  .dropdownFilter {
    max-width: 320px;
    flex: 0 0 calc(25% - 24px);
    margin-right: 24px;
  }
`;

export const TopBarStyled = styled.div`
  width: 100%;
  background-color: ${(props: any) => props.theme.neutral};
  border-bottom: 1px solid ${(props: any) => props.theme.neutral10};
  padding: 30px 0;
  position: fixed;
  top: 48px;
  z-index: 9998;
  &.smaller {
    padding: 20px 0;
  }
  .container {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    position: relative;
    .active-tasks-checkbox {
      margin-left: 24px;
    }
    ${FiltersWrapper} {
      flex: 0 0 100%;
    }
  }
  .active-filters {
    margin: 0 24px;
  }
  .right-actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    .styled--anchor {
      margin-right: 12px;
      svg {
        fill: transparent;
        stroke: ${(props: any) => props.theme.secondary};
        transition: all 0.3s linear;
      }
      &.onlyText:hover svg {
        fill: transparent;
        stroke: ${(props: any) => props.theme.primary};
      }
    }
  }
`;

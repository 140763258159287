// GaiaIframe.tsx
import React, { useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import { GaiaContainer, ToggleButton } from './GaiaFrame.style';

import archiveStore from 'stores/archiveStore';
import { setDisableButtonSearch } from 'views/archive/tabs/searchTab';

import loadingNotificationStore from 'stores/loadingNotificationStore';

import axios from 'axios';

let firstTime = true;

const searchAndFetchOutcomes = (outcome_ids: Set<string>) => {
  const ids=Array.from(outcome_ids);

  archiveStore.setSelectedTab("search")
  
  setTimeout(() => {
    archiveStore.setDateStart(new Date("2022"));
    archiveStore.setDropdownFilterValues("satl:outcome_id", "");
    archiveStore.updateArgs("satl:outcome_id", ids, "in");
    archiveStore.setAoiPolygon({
      "geometry": {
        "coordinates": [[[-180, -90], [180, -90], [180, 90], [-180, 90], [-180, -90]]]
      }
    });
    setTimeout(() => {
      setDisableButtonSearch(false);
      const button = document.querySelector('#do_search')?.querySelector('button');
      if (button) {
        setTimeout(() => button.click(), 500);
      }
    }, 500)      
  }, 500)
};

const GaiaIframe = () => {
  const [isMinimized, setIsMinimized] = useState(true);
  const [size, setSize] = useState({ width: 400 , height: 700 });

  const toggleIframe = () => {
    setIsMinimized(!isMinimized);
  };

  useEffect(() => {
    let last_msg_time = 0;  // Avoid duplication of messages... weird. The (iframe) event listener is not removed properly

    const handleIframeMessage = (event: MessageEvent) => {
      if (event?.data?.type?.startsWith('Aleph_')) {
        let data = event.data;

        if (data.msg_time > last_msg_time) {
          last_msg_time = data.msg_time;
          if (data.type === 'Aleph_loadGeojson') {
            data.data.forEach((url: string) => {
              axios.get(url)
              .then(response => {
                // let tiles = new Set(response.data.features.map((feature:any) => feature.properties.tile_id))
                let outcomes = new Set<string>(response.data.features.map((feature:any) => feature.properties.outcome_id))
                searchAndFetchOutcomes(outcomes);
              })
              .catch(error => {
                console.log(error.response ? error.response : error);
              })
            });
          }
        }
      }
    };

    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  return (
    <GaiaContainer>
      <ToggleButton onClick={toggleIframe}>
        {isMinimized ? 'Gaia💬' : '⬊'}
      </ToggleButton>
      {((firstTime && !isMinimized) || !firstTime) && !(firstTime = false) && (
        <Rnd
          size={{ width: size.width, height: size.height }}
          minWidth={200}
          minHeight={200}
          default={{
            x: -size.width-40,  // Initial position
            y: -size.height-40, // Initial position
            width: size.width,           // Initial width
            height: size.height,         // Initial height
          }}
          bounds="window"
          onResizeStop={(e, direction, ref, delta, position) => {
            setSize({
              width: ref.offsetWidth,
              height: ref.offsetHeight,
            });
          }}
          style={{
            display: (isMinimized && 'none') || 'flex',
            flexDirection: 'column',
          }}
        >
          <iframe
            id="GaiaFrame"
            src="https://opengaia.prd.aws.satellogic.team/"
            // src="http://10.130.1.4:3001/"
            title="Talk to Gaia"
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              borderRadius: '10px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            }}
          />
        </Rnd>
      )}
    </GaiaContainer>
  );
};

export default GaiaIframe;
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Tasking } from './views/tasking';
import { Playground } from './views/playground';
import { MyImages } from './views/images';
import { ProjectList } from './views/projects';
import { LoadingIcon, LogoutIcon } from 'components/icons';
import { Button } from 'components/button';
import { UnauthorizedModal } from 'components/modal/unauthorizedmodal';
import { ContractSelectionModal } from 'components/modal/contractselectionmodal/modal';

import { PATHS, SCOPES } from 'config';
import { useStores } from 'config/hooks';
import { Archive } from 'views/archive';
import { ItemProvider } from 'views/archive/tabs/infoTab/ItemContext';
import { TabProvider } from 'views/archive/TabContext';

type AuthRouteProps = {
  requireTaskingPermission?: boolean;
  requireArchivePermission?: boolean;
  requireGaiaPermission?: boolean;
  children: JSX.Element;
};

const NoMatch = observer(() => {
  const location = useLocation()
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  )
});

const NavigateToMain = observer(() => {
  const {
    rootStore: { userStore }
  } = useStores();

  const [redirectTo, setRedirectTo] = useState<string>('/');
  useEffect(() => {
      setRedirectTo(userStore.hasArchivePermission() ? PATHS.ARCHIVE : PATHS.TASKING)
  }, [userStore.activeContract]);

  return (
    <Navigate to={redirectTo} />
  );
});


const AuthRoute = observer(({
  requireTaskingPermission = false,
  requireArchivePermission = false,
  requireGaiaPermission = false,
  children,
}: AuthRouteProps) => {
  const {
    rootStore: { userStore }
  } = useStores();
  const [showSpinner, setShowSpinner] = useState(true);
  const [error, setError] = useState<string>('');

  const handleLogout = () => {
    userStore.logout();
  };
  useEffect(() => {
    const handleAuth = async () => {
      try {
        // Ensure Auth0 client is initialized
        if (!userStore.auth0Client) {
          await userStore.onInit();
        }

        // Check if user is authenticated
        const isAuthenticated = await userStore.isAuthenticated();
        if (!isAuthenticated) {
          return;
        }

        // Determine if the user has at least one required scope
        const required_scopes = Object.values(SCOPES);
        const hasRequiredScope = required_scopes.some(scope => userStore.scope.includes(scope));
        if (hasRequiredScope) {
          userStore.setOnboarded(true);
          setShowSpinner(false);
        } else if (!!userStore.scope) {
          // Attempt to onboard the user
          await userStore.onboard(
            () => { setShowSpinner(false) }
          );
        }
      } catch (err) {
        console.error('Authentication error:', err);
        setError("An error occurred during authentication.");
        setShowSpinner(false);
      }
    };

    handleAuth();
  }, [userStore.scope]);

  const styles = {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  if (showSpinner) {
    return (
      <div style={styles}>
        <LoadingIcon stroke="#002340" />
      </div>
    );
  }

  if (error || !userStore.onboarded) {
    return (
      <UnauthorizedModal
        errorMessage={error || "You don't have permission to access this service."}
        contactEmail="sales@satellogic.com"
        onLogout={handleLogout}
      />
    );
  }

  if (!userStore.activeContract) {
    return <ContractSelectionModal />
  }

  if (
    (requireTaskingPermission && !userStore.hasTaskingPermission()) ||
    (requireArchivePermission && !userStore.hasArchivePermission()) ||
    (requireGaiaPermission && !userStore.hasGaiaPermission())
  ) {
    return <NavigateToMain />;
  }

  return children;
});

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        
        <Route
          path={PATHS.IMAGES}
          element={
            <AuthRoute requireTaskingPermission={true}>
              <MyImages />
            </AuthRoute>
          }
        />
        <Route
          path={PATHS.PROJECTLIST}
          element={
            <AuthRoute requireTaskingPermission={true}>
              <ProjectList />
            </AuthRoute>
          }
        />
        <Route
          path={PATHS.TASKING}
          element={
            <AuthRoute requireTaskingPermission={true}>
              <Tasking />
            </AuthRoute>
          }
        />
        <Route
          path={PATHS.ROOT}
          element={
            <AuthRoute>
              <NavigateToMain />
            </AuthRoute>
          }
        />

        <Route
          path={PATHS.ARCHIVE}
          element={
            <AuthRoute requireArchivePermission={true}>
              <ItemProvider>
                <TabProvider>
                  <Archive />
                </TabProvider>
              </ItemProvider>
            </AuthRoute>
          }
        />
        <Route element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  );
};

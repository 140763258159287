import { Feature, FeatureCollection } from '@turf/helpers';
import { getAllGeometryTypes, isFeature, isFeatureCollection, } from './helpers';

export const handleFileUpload = (file: File, newPolyCallback: (poly: Feature | undefined) => void) => {
    
    const reader = new FileReader();
    reader.onload = (event) => {

      if (event.target && event.target.result) {
        try {
          const fileExtension = file.name.split('.').pop()?.toLowerCase();
          let geoJson: FeatureCollection<Geometry> | undefined = undefined;

          switch (fileExtension) {
            case "geojson":
            case "json":
              geoJson = JSON.parse(event.target.result as string);

              // Validate we can handle the JSON
              if (isFeature(geoJson)) {
                //console.log("Single", geoJson);                
              }
              else if (isFeatureCollection(geoJson)) {
                const featureTypes = getAllGeometryTypes(geoJson)
                if (featureTypes.length > 1) {
                  throw new Error('GeoJSON features should be all the same, points, polygons, etc.');
                }
              }
              else {
                throw new Error('GeoJSON must be a Feature or a FeatureCollection with exactly one feature.');
              }
              break;

            case "kml":
              const parser = new DOMParser();
              const kmlDoc = parser.parseFromString(event.target.result as string, 'text/xml');

              //geoJson = togeojson.kml(kmlDoc);
              break;

            default:
              throw new Error('Not supported file type');
          }
          
          newPolyCallback(geoJson as unknown as Feature);          
                  
        } catch (error) {
          //notificationStore.add({ description: 'Invalid GeoJSON file: ' + error });
          console.error('Invalid GeoJSON file:', error);
        }
      }
    };
    reader.readAsText(file);
  };